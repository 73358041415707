import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react'
import { route, INITIAL_ROUTE, NOTIFICATION } from 'routes'
import Typography from '@mui/material/Typography'
import { Dispatch, bindActionCreators } from 'redux'
import { Route, Routes, Navigate } from 'react-router-dom'
import { RootActionType, RootState } from 'duck'
import { connect, useDispatch } from 'react-redux'
import useInterval from 'use-interval'
import clsx from 'clsx'
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { History } from 'history'
import { HistoryRouter as Router } from 'redux-first-history/rr6'
import HomePage from 'containers/HomePage/Loadable'
import ImageEnhancementPanel from 'containers/ImageEnhancementPanel'
import DndProvider from 'utils/DndProvider'
import { LoadingPage } from 'components/Loading'
import NavBar from 'containers/NavBar'
import NotFoundPage from 'containers/NotFoundPage/Loadable'
import ExplorePage from 'containers/ExplorePage/Loadable'
import ProfilePage from 'containers/ProfilePage/Loadable'
import ArtistPage from 'containers/ArtistPage/Loadable'

import LegacyProjectPage from 'containers/LegacyProjectRoute/Loadable'
import WelcomePage from 'containers/WelcomePage/Loadable'
import ProtectedRoute from 'containers/ProtectedRoute'
import TestingRoute from 'containers/TestingRoute'
import UserDebugPage from 'containers/UserDebugPage'
import MonetizationDialog from 'containers/MonetizationDialog/Loadable'
import { MonetizationDialog as MonetizationDialogConstant } from 'duck/AppDuck/DialogDuck'
import ProcessesPage from 'containers/ProcessesPage/Loadable'
import Signin from 'containers/Signin/Loadable'
import DialogContinueSubscription from 'containers/MonetizationDialog/DialogContinueSubscription'
import DialogContinueInactiveSubscription from 'containers/MonetizationDialog/DialogContinueInactiveSubscription'
import ErrorBoundary from './ErrorBoundary'
import AppSubpage from './AppSubpage'
import ImageCropperDialog from 'containers/AppPage/Dialogs/ImageCropperDialog/Loadable'
import AppPageLocationAddons from './AppPageLocationAddons'
import Snackbar from 'components/Snackbar'
import AppPageBanner from './AppPageBanner'
import UIShowcase from 'containers/UIShowcase/Loadable'
import ProcessesPanel from 'containers/ProcessesPanel/Loadable'
import SubscriptionPanel from 'containers/SubscriptionPanel/Loadable'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import { appActions, appSelectors } from 'duck/AppDuck'
import GeneralDialog from './Dialogs'
import PhoneVerificationDialog from './Dialogs/PhoneVerificationDialog/Loadable'
import EmailPreferencesDialog from './Dialogs/EmailPreferencesDialog/Loadable'
import QuestionDialog from './Dialogs/QuestionDialog/Loadable'
import EmailForSigninLinkDialog from 'containers/AppPage/Dialogs/EmailForSigninLinkDialog'
import DialogImageDownload from 'containers/AppPage/Dialogs/DialogImageDownload/Loadable'
import { BreakpointContext, WindowDimensionContext } from 'appContext'
import {
  useInitializeAuth,
  useUserDeviceDetector,
  useBreakpointInit,
  useWindowDimensionInit,
  useSingleDownloadLoading,
  useShouldShowContinueSubscriptionDialog,
  useShouldShowInactiveSubscriptionDialog
} from './AppPageHooks'
import ShareFeedDialog from 'containers/ShareFeedPanel/ShareFeedDialog/Loadable'
import { firebaseActions } from 'duck/FirebaseDuck'
import { phoneActions } from 'duck/AppDuck/PhoneDuck'
import BannerSubscriptionUpsell from 'components/Banner/BannerSubscriptionUpsell'
import {
  LSKey,
  REFRESH_NOTIFICATION_INTERVAL,
  REFRESH_NOTIFICATION_INTERVAL_OPENED
} from 'appConstants'
import playformTheme from 'playformTheme'
import { SessionStorage } from 'utils'
import ButtonText from 'components/Button/ButtonText'
import { usePortalInit } from 'components/Portal'
import { downloaderSelectors } from 'duck/AppDuck/DownloaderDuck'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'
import { bannerActions } from 'duck/AppDuck/BannerDuck'
import { changeEmailActions } from 'duck/AppDuck/ChangeEmailDuck'
import { ReduxProps } from 'utils/Types'
import NFTSellPanel from 'containers/NFTSellPanel'
import MetamaskWalletDialog from './Dialogs/MetamaskWalletDialog'
import BannerNFTSuccess from 'components/Banner/BannerNFTSuccess/Loadable'
import { dialogActions } from 'duck/AppDuck/DialogDuck'
import SEOHelmet from './SEOHelmet'
import { usePrevious } from 'utils/Hooks'
import styles from './App.module.scss'

const mapStateToProps = (state: RootState) => ({
  hasSubPage: appSelectors.hasSubPage(state),
  authenticating: appSelectors.authenticating(state),
  snackBar: appSelectors.snackBar.snackBar(state),
  activeMenu: appSelectors.activeMenu(state),
  unreadCount: apiSelectors.unreadCount(state),
  canDebugUser: apiSelectors.user(state)?.can_debug_user,
  isAuthorizationComplete: appSelectors.isAuthorizationComplete(state),
  isUserLoading: appSelectors.isUserLoading(state),
  userDeviceInfo: appSelectors.userDeviceInfo(state),
  upsellGlobalBanner: appSelectors.banner.upsellGlobalBanner(state),
  downloadProgressSingleText: downloaderSelectors.downloadProgressSingleText(state),
  isAuthorized: appSelectors.isAuthorized(state),
  equity: apiSelectors.equity(state),
  brainTreePaymentMethod: apiSelectors.brainTreePaymentMethod(state),
  hasBraintreePaymentMethod: apiSelectors.hasBraintreePaymentMethod(state),
  currentSubscription: apiSelectors.currentSubscription(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      setFirebaseUser: firebaseActions.setFirebaseUser,
      startTokenInterval: firebaseActions.startTokenInterval,
      setIdToken: firebaseActions.setIdToken,
      setAuthenticating: appActions.setAuthenticating,

      retrieveApiUser: apiActions.users.retrieve,
      retrieveUnreadNotificationCount: apiActions.users.retrieveUnreadNotificationCount,
      retrieveApiUserPermission: apiActions.users.retrievePermission,
      retrieveConfigVariable: apiActions.users.retrieveConfigVariable,
      retrieveEquity: apiActions.users.retrieveEquity,
      braintreeRetrievePaymentMethod: apiActions.payment.braintreeRetrievePaymentMethod,
      retrieveEquityConfig: apiActions.users.retrieveEquityConfig,
      closeSnackbar: snackBarActions.close,
      showSnackbar: snackBarActions.show,
      openUpgradePage: appActions.openUpgradePage,

      checkUpdatePhone: phoneActions.checkUpdatePhone,
      closeUpsellBanner: bannerActions.upsell.close,

      didMountApp: changeEmailActions.didMountApp,
      didMountAfterLogin: changeEmailActions.didMountAfterLogin,
      retrieveCreditOverview: apiActions.payment.retrieveCreditOverview,
      retrieveSummary: apiActions.projects.retrieveSummary,
      setUserDevice: appActions.userDevice.setUserDevice,
      addDialog: dialogActions.addDialog,
      listEngineConfig: appActions.listEngineConfig,

      listSubscription: apiActions.payment.listSubscription,
      setUserHasSubscriptionNoPaymentMethod: apiActions.users.setUserHasSubscriptionNoPaymentMethod
    },
    dispatch
  )

const RootPage: React.FC<{ isAuthorized: boolean }> = ({ isAuthorized }) => {
  return isAuthorized ? <Navigate to={INITIAL_ROUTE} /> : <Navigate to={INITIAL_ROUTE} />
}

export type AppPageProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const App: React.FC<AppPageProps> = props => {
  const {
    snackBar,
    isAuthorizationComplete,
    isUserLoading,
    userDeviceInfo,
    upsellGlobalBanner,
    isAuthorized,
    authenticating,
    canDebugUser,
    downloadProgressSingleText,
    unreadCount,
    activeMenu,
    hasSubPage,
    equity,
    brainTreePaymentMethod,
    currentSubscription,
    hasBraintreePaymentMethod,
    setUserHasSubscriptionNoPaymentMethod,
    listSubscription,
    braintreeRetrievePaymentMethod,
    addDialog,
    showSnackbar,
    closeSnackbar,
    setUserDevice,
    openUpgradePage,
    closeUpsellBanner,
    didMountAfterLogin,
    listEngineConfig,
    didMountApp,
    retrieveEquity,
    retrieveEquityConfig,
    retrieveApiUserPermission,
    retrieveCreditOverview,
    retrieveSummary,
    checkUpdatePhone,
    startTokenInterval,
    setIdToken,
    setFirebaseUser,
    retrieveApiUser,
    retrieveConfigVariable,
    setAuthenticating,
    retrieveUnreadNotificationCount
  } = props
  const dispatch = useDispatch()
  const useDarkMode = userDeviceInfo?.summary?.isDarkMode || false
  const appPageRef = useRef<HTMLDivElement | null>(null)

  useInitializeAuth({
    startTokenInterval,
    setIdToken,
    retrieveApiUser,
    setFirebaseUser,
    setAuthenticating
  })
  useSingleDownloadLoading({ downloadProgressSingleText, closeSnackbar, showSnackbar })
  useUserDeviceDetector(userDeviceInfo, setUserDevice)

  useShouldShowContinueSubscriptionDialog({
    isAuthorizationComplete,
    equity,
    hasBraintreePaymentMethod,
    brainTreePaymentMethod,
    currentSubscription,
    setUserHasSubscriptionNoPaymentMethod,
    listSubscription,
    addDialog,
    braintreeRetrievePaymentMethod
  })

  useShouldShowInactiveSubscriptionDialog({
    currentSubscription,
    addDialog
  })

  const helmetLinkProps = useMemo(() => {
    if (!useDarkMode) return undefined

    let helmetLinkProps: Array<any> = []

    if (!useDarkMode) {
      helmetLinkProps.push({
        type: 'link',
        rel: 'shortcut icon',
        href: '/assets/images/favicon/favicon.png'
      })

      helmetLinkProps.push({
        type: 'link',
        rel: 'manifest',
        href: '/manifest.json'
      })
    } else {
      helmetLinkProps.push({
        type: 'link',
        rel: 'shortcut icon',
        href: '/assets/images/favicon/favicon-white.png'
      })

      helmetLinkProps.push({
        type: 'link',
        rel: 'manifest',
        href: '/manifest-white.json'
      })
    }

    return helmetLinkProps
  }, [useDarkMode])

  const onCloseUpsellBanner = useCallback(() => {
    closeUpsellBanner({ upsellLocation: 'global' })
  }, [closeUpsellBanner])

  const onClickUpgrade = useCallback(() => {
    openUpgradePage()
    onCloseUpsellBanner()
  }, [onCloseUpsellBanner, openUpgradePage])

  const onClickBuyCredit = useCallback(() => {
    addDialog({
      [MonetizationDialogConstant.ADD_CREDIT]: {
        dialogName: MonetizationDialogConstant.ADD_CREDIT,
        source: 'upsell'
      }
    })
    onCloseUpsellBanner()
  }, [addDialog, onCloseUpsellBanner])

  const storedEmail = SessionStorage.get(LSKey.USER_DEBUG_EMAIL)

  const [shouldRenderRouter, setShouldRenderRouter] = useState(false)
  const hasSubPagePrev = usePrevious(hasSubPage)
  const appPageWillMount =
    (!shouldRenderRouter && typeof hasSubPagePrev === 'undefined' && hasSubPage === false) ||
    (authenticating === false && isUserLoading === false && !isAuthorized)
  const appSubpageWillMount =
    !shouldRenderRouter && typeof hasSubPagePrev === 'undefined' && hasSubPage === true

  usePortalInit('appPage', appPageRef)

  useInterval(
    () => {
      if (isAuthorizationComplete) {
        retrieveUnreadNotificationCount()
      }
    },
    isAuthorizationComplete
      ? activeMenu === NOTIFICATION && unreadCount
        ? REFRESH_NOTIFICATION_INTERVAL_OPENED
        : REFRESH_NOTIFICATION_INTERVAL
      : null,
    true
  )

  useEffect(() => {
    // First mounted
    didMountApp()

    // Remove first load placeholder component
    const firstloadStyles = document.getElementById('firstloadStyles')
    const firstloadComponent = document.getElementById('firstloadPlaceholder')

    window.setTimeout(() => {
      if (firstloadStyles) firstloadStyles?.parentNode?.removeChild(firstloadStyles)
      if (firstloadComponent) firstloadComponent?.parentNode?.removeChild(firstloadComponent)
    }, 300)
  }, [didMountApp])

  useEffect(() => {
    retrieveConfigVariable()
  }, [retrieveConfigVariable])

  useEffect(() => {
    if (isAuthorizationComplete) {
      didMountAfterLogin()
      listEngineConfig()
      retrieveEquity()
      retrieveEquityConfig()
      retrieveApiUserPermission()
      retrieveCreditOverview()
      retrieveSummary()
      checkUpdatePhone()
    }
  }, [
    checkUpdatePhone,
    didMountAfterLogin,
    isAuthorizationComplete,
    listEngineConfig,
    retrieveApiUserPermission,
    retrieveCreditOverview,
    retrieveEquity,
    retrieveEquityConfig,
    retrieveSummary
  ])

  useEffect(() => {
    /* Define first level router render */

    if (appSubpageWillMount) {
      window.setTimeout(() => {
        setShouldRenderRouter(true)
      }, 1000)
    } else if (appPageWillMount) {
      setShouldRenderRouter(true)
    }
  }, [appPageWillMount, appSubpageWillMount])

  return (
    <DndProvider>
      <SEOHelmet link={helmetLinkProps}>
        <meta name="facebook-domain-verification" content="ogrphbwzmwvj2cs4ht8x1hrr60fo7w" />
      </SEOHelmet>

      {/* AppPageLocationAddons, by make it as a component, it won't affect to AppPage */}
      <AppPageLocationAddons />
      {/* AppPageLocationAddons END */}

      <Snackbar {...snackBar} dispatch={dispatch} onClose={closeSnackbar} />

      <div ref={appPageRef} className={clsx(styles.AppPage, hasSubPage && styles.AppPageHidden)}>
        <NavBar authenticating={authenticating} />

        {!hasSubPage ? <AppPageBanner /> : null}

        {!shouldRenderRouter ? <LoadingPage delay={1} /> : null}

        <Routes>
          {shouldRenderRouter ? (
            <>
              <Route
                path={`${route.ROOT.paths[0]}`}
                element={<RootPage isAuthorized={isAuthorized} />}
              />
              <Route
                path={`${route.ROOT.getPath(2)}/*`}
                element={<ProtectedRoute page={<HomePage />} />}
              >
                <Route
                  path={`${route.ROOT.paths[2]}/*`}
                  element={<ProtectedRoute page={<HomePage />} />}
                >
                  <Route
                    path={`${route.ROOT.paths[3]}/*`}
                    element={<ProtectedRoute page={<HomePage />} />}
                  />
                </Route>
              </Route>
              <Route path={`${route.EXPLORE.paths[0]}`}>
                <Route index element={<ExplorePage />} />
                <Route path={`${route.EXPLORE.paths[1]}`} element={<ExplorePage />}>
                  <Route path={`${route.EXPLORE.paths[2]}`} element={<ExplorePage />}>
                    <Route path={`${route.EXPLORE.paths[3]}`} element={<ExplorePage />} />
                  </Route>
                </Route>
              </Route>
              {canDebugUser ? (
                <Route
                  path={`${route.USER_DEBUG.paths[0]}`}
                  element={<ProtectedRoute page={<UserDebugPage />} />}
                />
              ) : null}
              <Route
                path={`${route.WELCOME.paths[0]}`}
                element={<ProtectedRoute page={<WelcomePage />} />}
              />
              <Route
                path={`${route.PROCESSES.paths[0]}`}
                element={<ProtectedRoute page={<ProcessesPage />} />}
              />
              <Route
                path={`${route.PROFILE.paths[0]}`}
                element={<ProtectedRoute page={<ProfilePage />} />}
              />
              <Route path={`${route.ARTISTS.getPath(2)}`} element={<ArtistPage />}>
                <Route path={`${route.ARTISTS.getPath(3)}`} element={<ArtistPage />}></Route>
              </Route>
              <Route
                path={`${route.UI_SHOWCASE.paths[0]}`}
                element={<TestingRoute page={<UIShowcase />} />}
              />
              <Route path={route.SIGN_IN.paths[0]} element={<Signin />} />
              <Route path={route.NOT_FOUND.paths[0]} element={<NotFoundPage />} />
              {/* Legacy Route */}
              <Route
                path={route.TRAIN_PROJECT.getPath(2)}
                element={<LegacyProjectPage projectType={'train'} />}
              >
                <Route
                  path={route.TRAIN_PROJECT.paths[2]}
                  element={<LegacyProjectPage projectType={'train'} />}
                >
                  <Route
                    path={route.TRAIN_PROJECT.paths[3]}
                    element={<LegacyProjectPage projectType={'train'} />}
                  >
                    <Route
                      path={route.TRAIN_PROJECT.paths[4]}
                      element={<LegacyProjectPage projectType={'train'} />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route
                path={route.MIX_IMAGE.getPath(2)}
                element={<LegacyProjectPage projectType={'mix'} />}
              >
                <Route
                  path={route.MIX_IMAGE.paths[2]}
                  element={<LegacyProjectPage projectType={'mix'} />}
                />
              </Route>
              <Route
                path={route.SKETCH_TO_IMAGE.getPath(2)}
                element={<LegacyProjectPage projectType={'sketch'} />}
              />
              <Route
                path={route.STYLIZE.getPath(2)}
                element={<LegacyProjectPage projectType={'pro-filter'} />}
              />
              <Route path={'*'} element={<NotFoundPage />} />
            </>
          ) : null}
        </Routes>
      </div>

      {!authenticating && isAuthorized && !isUserLoading ? <AppSubpage /> : null}

      <GeneralDialog />
      <EmailForSigninLinkDialog />
      {isAuthorizationComplete ? (
        <>
          <ImageEnhancementPanel />
          <QuestionDialog />
          <ShareFeedDialog />
          <MonetizationDialog />
          <ImageCropperDialog />
          <SubscriptionPanel />
          <MetamaskWalletDialog />
          <DialogContinueSubscription />
          <DialogContinueInactiveSubscription />
          <PhoneVerificationDialog />
          <EmailPreferencesDialog />
          <ProcessesPanel floating />
          <BannerSubscriptionUpsell
            show={upsellGlobalBanner.show}
            dismissable={upsellGlobalBanner.dismissable}
            position={upsellGlobalBanner.position ?? 'inside-content'}
            contentMode={upsellGlobalBanner.contentMode}
            onClickUpgrade={onClickUpgrade}
            onClose={onCloseUpsellBanner}
            onClickBuyCredit={onClickBuyCredit}
          />
          <BannerNFTSuccess />
          <DialogImageDownload />
          <NFTSellPanel />
        </>
      ) : null}

      {storedEmail ? (
        <div className="fixed left-0 top-0 z-9999 bg-red-700 px-3 opacity-60">
          <Typography>
            You're viewing as : <b>{storedEmail}</b>
            <ButtonText className="ml-4" to={route.USER_DEBUG.getUrl()}>
              Turn Off
            </ButtonText>
          </Typography>
        </div>
      ) : null}
    </DndProvider>
  )
}

const AppWithConnect = connect(mapStateToProps, mapDispatchToProps)(App)

const AppWithContext = () => {
  const windowDimensionData = useWindowDimensionInit()
  const breakpointData = useBreakpointInit()

  return (
    <WindowDimensionContext.Provider value={windowDimensionData}>
      <BreakpointContext.Provider value={breakpointData}>
        <AppWithConnect />
      </BreakpointContext.Provider>
    </WindowDimensionContext.Provider>
  )
}

type AppWithErrorBoundaryProps = {
  history: History
}

const AppWithErrorBoundary: React.FC<AppWithErrorBoundaryProps> = ({ history }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={playformTheme}>
      <CssBaseline />
      <Router history={history}>
        <ErrorBoundary>
          <AppWithContext />
        </ErrorBoundary>
      </Router>
    </ThemeProvider>
  </StyledEngineProvider>
)

export default AppWithErrorBoundary

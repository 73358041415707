import React, { MouseEventHandler, useEffect, useMemo } from 'react'
import Banner from '../'
import _compact from 'lodash/compact'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'

import { emptyFunction } from 'utils'
import { useDelayedData, useBreakpoint, useBooleanDelayedData } from 'utils/Hooks'
import { values } from 'appConstants'
import { ButtonProps } from 'components/Button'
import BannerFloating from 'components/Banner/BannerFloating'

import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './BannerSubscriptionUpsell.module.scss'
import { useSelector } from 'react-redux'
import { Utils } from 'containers/SubscriptionPanel/duck'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import { useDispatch } from 'react-redux'

export type ContentModeProps =
  | 'used-up'
  | 'almost-used-up'
  | 'upscale'
  | 'batch-download'
  | 'upscale-advanced'
  | 'upload-mix'
  | 'text-image-advanced'
  | 'genericapp-plus'
  | 'mixvideo-generate'
  | undefined

export type BannerSubscriptionUpsellProps = {
  show?: boolean
  onClickUpgrade: MouseEventHandler
  onClickBuyCredit?: MouseEventHandler
  dismissable?: boolean
  contentMode?: ContentModeProps
  position: 'inside-content' | 'float-bottom-banner' | 'float-bottom-banner-overlay'
  onClose?: MouseEventHandler
}

const BannerSubscriptionUpsell: React.FC<BannerSubscriptionUpsellProps> = props => {
  const {
    position,
    show,
    dismissable,
    contentMode,
    onClose = emptyFunction,
    onClickUpgrade,
    onClickBuyCredit
  } = props
  const {
    breakpoint
    // isLargescreenBelow
  } = useBreakpoint()
  const disableAnimaton = position === 'inside-content'
  const withOverlay = position === 'float-bottom-banner-overlay'
  const isFloatBottom =
    position === 'float-bottom-banner' || position === 'float-bottom-banner-overlay'
  const isExtraSmallscreen = breakpoint === 'xs'

  const productsData = useSelector(apiSelectors.productsData)
  const hasProducts = useSelector(apiSelectors.hasProducts)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!hasProducts) {
      dispatch(apiActions.payment.retrieveProducts())
    }
  }, [dispatch, hasProducts])

  const { plusMonthlyPriceText } = Utils.getPriceTextVersion(productsData)

  const actionsAdjusted = useMemo(() => {
    return _compact([
      {
        label: 'UPGRADE',
        onClick: onClickUpgrade
      },
      onClickBuyCredit
        ? {
            label: 'BUY CREDITS',
            onClick: onClickBuyCredit,
            color: 'light' as ButtonProps['color']
          }
        : null,
      dismissable
        ? { label: 'DISMISS', onClick: onClose, color: 'light' as ButtonProps['color'] }
        : null
    ])
  }, [onClickUpgrade, dismissable, onClose])

  const contentModeState = useDelayedData(
    show ? contentMode : undefined,
    values.DIALOG_TRANSITION_DURATION
  )
  const actionsAdjustedState = useDelayedData(
    show ? actionsAdjusted : undefined,
    values.DIALOG_TRANSITION_DURATION
  )

  const BannerContent = (
    <Banner
      placeholderClassName={clsx(
        !isFloatBottom && stylesGlobal.BorderBottomOnSurface,
        isFloatBottom && stylesGlobal.BgColorInherit,
        styles.BannerSubscriptionUpsellPlaceholder
      )}
      className={styles.BannerSubscriptionUpsell}
      show={show}
      // disableAnimaton={isFloatBottom}
      onClose={onClose}
      actions={actionsAdjustedState}
      content={
        <div>
          {contentModeState === 'almost-used-up' ? (
            <>
              <Typography
                className={isExtraSmallscreen ? 'mb-1' : 'mb-2'}
                variant={isExtraSmallscreen && disableAnimaton ? 'body1' : 'h5'}
              >
                Upgrade to <b>Pro</b> for Unlimited generations.
              </Typography>

              <Typography
                className="text-white-med-on-dark"
                variant={isExtraSmallscreen && disableAnimaton ? 'body2' : 'subtitle2'}
              >
                We’re glad to see that you’re enjoying using Playform to generate AI artwork. Don’t
                get limited by upgrading to Pro.
              </Typography>
            </>
          ) : null}

          {contentModeState === 'used-up' ? (
            <>
              <Typography
                className={isExtraSmallscreen ? 'mb-1' : 'mb-2'}
                variant={isExtraSmallscreen && disableAnimaton ? 'body1' : 'h5'}
              >
                {`You have reached the daily limit for free generation`}
              </Typography>
              <Typography
                className="text-white-med-on-dark"
                variant={isExtraSmallscreen && disableAnimaton ? 'body2' : 'subtitle2'}
              >
                {`Upgrade to PLUS to got unlimited generation for only ${plusMonthlyPriceText}/month`}
              </Typography>
            </>
          ) : null}

          {contentModeState === 'upscale' ? (
            <>
              <Typography
                className={isExtraSmallscreen ? 'mb-1' : 'mb-2'}
                variant={isExtraSmallscreen && disableAnimaton ? 'body1' : 'h5'}
              >
                Upgrade to <b>Plus</b> to upscale your result.
              </Typography>
              <Typography
                className="text-white-med-on-dark"
                variant={isExtraSmallscreen && disableAnimaton ? 'body2' : 'subtitle2'}
              >
                We’re glad to see that you’re enjoying using Playform to generate AI artwork. Don’t
                get limited by upgrading to Plus.
              </Typography>
            </>
          ) : null}

          {contentModeState === 'upload-mix' ? (
            <>
              <Typography
                className={isExtraSmallscreen ? 'mb-1' : 'mb-2'}
                variant={isExtraSmallscreen && disableAnimaton ? 'body1' : 'h5'}
              >
                Upgrade to <b>Plus</b> to upload more images.
              </Typography>
              <Typography
                className="text-white-med-on-dark"
                variant={isExtraSmallscreen && disableAnimaton ? 'body2' : 'subtitle2'}
              >
                We’re glad to see that you’re enjoying using Playform to generate AI artwork. Don’t
                get limited by upgrading to Plus.
              </Typography>
            </>
          ) : null}

          {contentModeState === 'upscale-advanced' ? (
            <>
              <Typography
                className={isExtraSmallscreen ? 'mb-1' : 'mb-2'}
                variant={isExtraSmallscreen && disableAnimaton ? 'body1' : 'h5'}
              >
                Upgrade to <b>Plus</b> unlock advanced AI upscaling.
              </Typography>
              <Typography
                className="text-white-med-on-dark"
                variant={isExtraSmallscreen && disableAnimaton ? 'body2' : 'subtitle2'}
              >
                Plus includes bulk downloading of training results, upscaling, larger storage, and
                more.
              </Typography>
            </>
          ) : null}

          {contentModeState === 'batch-download' ? (
            <>
              <Typography
                className={isExtraSmallscreen ? 'mb-1' : 'mb-2'}
                variant={isExtraSmallscreen && disableAnimaton ? 'body1' : 'h5'}
              >
                Upgrade to <b>Plus</b> to download multiple images
              </Typography>
              <Typography
                className="text-white-med-on-dark"
                variant={isExtraSmallscreen && disableAnimaton ? 'body2' : 'subtitle2'}
              >
                We’re glad to see that you’re enjoying using Playform to generate AI artwork. Don’t
                get limited by upgrading to Plus.
              </Typography>
            </>
          ) : null}

          {contentModeState === 'text-image-advanced' ? (
            <>
              <Typography
                className={isExtraSmallscreen ? 'mb-1' : 'mb-2'}
                variant={isExtraSmallscreen && disableAnimaton ? 'body1' : 'h5'}
              >
                Upgrade to <b>Pro</b> to access Text To Art advanced options
              </Typography>
              <Typography
                className="text-white-med-on-dark"
                variant={isExtraSmallscreen && disableAnimaton ? 'body2' : 'subtitle2'}
              >
                We’re glad to see that you’re enjoying using Playform to generate AI artwork. Don’t
                get limited by upgrading to Pro.
              </Typography>
            </>
          ) : null}

          {contentModeState === 'genericapp-plus' ? (
            <>
              <Typography
                className={isExtraSmallscreen ? 'mb-1' : 'mb-2'}
                variant={isExtraSmallscreen && disableAnimaton ? 'body1' : 'h5'}
              >
                Upgrade to <b>Plus</b> to use this AI Application
              </Typography>
              <Typography
                className="text-white-med-on-dark"
                variant={isExtraSmallscreen && disableAnimaton ? 'body2' : 'subtitle2'}
              >
                We’re glad to see that you’re enjoying using Playform to generate AI artwork. Don’t
                get limited by upgrading to Plus.
              </Typography>
            </>
          ) : null}

          {contentModeState === 'mixvideo-generate' ? (
            <>
              <Typography
                className={isExtraSmallscreen ? 'mb-1' : 'mb-2'}
                variant={isExtraSmallscreen && disableAnimaton ? 'body1' : 'h5'}
              >
                Upgrade to <b>Plus</b> to generate video from clips
              </Typography>
              <Typography
                className="text-white-med-on-dark"
                variant={isExtraSmallscreen && disableAnimaton ? 'body2' : 'subtitle2'}
              >
                We’re glad to see that you’re enjoying using Playform to generate AI artwork. Don’t
                get limited by upgrading to Plus.
              </Typography>
            </>
          ) : null}
        </div>
      }
    />
  )

  if (isFloatBottom)
    return (
      <BannerFloating
        open={Boolean(show)}
        onClose={withOverlay ? onClose : undefined}
        hideBackdrop={!withOverlay}
      >
        {BannerContent}
      </BannerFloating>
    )

  return BannerContent
}

const BannerSubscriptionUpsellContainer: React.FC<BannerSubscriptionUpsellProps> = props => {
  const show = useBooleanDelayedData(props.show || false)

  if (!show) return null

  return <BannerSubscriptionUpsell {...props} />
}

export default BannerSubscriptionUpsellContainer

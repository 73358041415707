import { TextTransform, UrlUtils } from 'utils/TextUtils'
import produce from 'immer'
import _compact from 'lodash/compact'
import _toNumber from 'lodash/toNumber'
import { combineEpics, Epic } from 'redux-observable'
import {
  filter,
  map,
  withLatestFrom,
  delay,
  mergeMap,
  startWith,
  switchMap,
  catchError,
  take,
  tap
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { ActionType, getType, isActionOf, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import {
  BASE_GENERATE_SIZE,
  GenerateResolution,
  GENERATE_RESOLUTION_PAIR,
  REFINEMENT_STEPS,
  SketchTextCreateReq,
  SketchTextGenerateReq,
  SketchTextProject,
  SketchTextUploadReq
} from 'models/ApiModels'
import { replace } from 'redux-first-history'
import { apiActions, apiSelectors, sharedActions } from 'duck/ApiDuck'
import { dialogActions, ErrorDialog } from 'duck/AppDuck/DialogDuck'
import { downloaderActions } from 'duck/AppDuck/DownloaderDuck'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'
import { route } from 'routes'
import { of, merge, from } from 'rxjs'
import { errorUtils } from 'utils/DataProcessingUtils'
import SentryUtils from 'utils/SentryUtils'
import { values } from 'appConstants'
import { SelectBaseItemType } from 'components/Select/SelectBase'
import { ImageEditData } from 'components/ImageCropper'
import { bannerActions, ShowUpsellParam } from 'duck/AppDuck/BannerDuck'
import { SketchCommandType } from 'components/Sketch'
import FacebookPixelUtils from 'utils/FacebookPixelUtils'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import { ApiUtils } from 'utils'
import { Command } from 'components/ImageSketchMask'
import userSelectors from 'duck/ApiDuck/selectors/UserSelectors'

// Constants
const NAMESPACE = '@@page/SketchTextToImagePage'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export const GENERATING_POOL_INTERVAL = 2000
export const ADDITIONAL_THRESHOLD_GENERATE = 40 //in second
export const LOAD_OUTPUT_AFTER_GENERATE_LOADING = 'load_output_after_generate'

export const ADVANCED_OPTIONS_UPSELL_CONFIG: ShowUpsellParam = {
  dismissable: true,
  upsellLocation: 'global',
  contentMode: 'text-image-advanced',
  position: 'float-bottom-banner-overlay'
}

export const PROCESS_CODENAME = {
  IMAGE_ONLY: 'image_only',
  PROMPT_SKETCH: 'prompt_sketch',
  PROMPT_ONLY: 'prompt_only',
  PROMPT_IMAGE: 'prompt_image',
  MASK_IN: 'mask_in',
  MASK_OUT: 'mask_out'
}

const PROMPT_REQUIRED = [
  PROCESS_CODENAME.PROMPT_SKETCH,
  PROCESS_CODENAME.PROMPT_ONLY,
  PROCESS_CODENAME.PROMPT_IMAGE
]

const IMAGE_REQUIRED = [
  PROCESS_CODENAME.IMAGE_ONLY,
  PROCESS_CODENAME.PROMPT_IMAGE,
  PROCESS_CODENAME.MASK_IN,
  PROCESS_CODENAME.MASK_OUT
]

const MASK_REQUIRED = [PROCESS_CODENAME.MASK_IN, PROCESS_CODENAME.MASK_OUT]

export type FormData = Pick<
  SketchTextGenerateReq,
  | 'prompt'
  | 'prompt_negative'
  | 'seed'
  | 'process'
  | 'model'
  | 'prompt_strength'
  | 'control_strength'
  | 'samples'
  | 'enhance_prompt'
  | 'mask'
> & {
  sketch?: File
  input_data?: string
  image?: number
  imageFileOriginal?: File
  imageFileCropped?: File
  imageFileEdit?: ImageEditData
  resolution?: string
  isMaskEmpty?: boolean
}

export const Utils = {
  getResolutionText: (data: GenerateResolution): string => `${data.width}-${data.height}`,
  getGenerateParamFromText: (
    data?: string
  ): Omit<GenerateResolution, 'aspectRatio'> | undefined => {
    const split = data && data.split('-')
    return split
      ? {
          width: _toNumber(split[0]),
          height: _toNumber(split[1])
        }
      : undefined
  }
}

// Actions
export const sketchTextToImageActions = {
  setEnableBackgroundTransform: createAction(creator('ENABLE_BACKGROUND_TRANSFORM'))<boolean>(),
  setSketchMode: createAction(creator('SET_SKETCH_MODE'))<SketchTextToImageState['sketchMode']>(),
  uploadSketch: createAction(creator('UPLOAD_SKETCH'))<SketchTextUploadReq>(),
  init: createAction(creator('INIT'))<SketchTextProject>(),
  setSketchTextProjectId: createAction(creator('SET_SKETCH_TEXT_PROJECT_ID'))<number | undefined>(),
  setSketchCommand: createAction(creator('SET_SKETCH_COMMAND'))<SketchCommandType | null>(),
  setMaskCommand: createAction(creator('SET_MASK_COMMAND'))<Command | null>(),
  createSketchTextProject: createAction(
    creator('CREATE_SKETCH_TEXT_PROJECT')
  )<SketchTextCreateReq>(),
  updateFormData: createAction(creator('UPDATE_FORM_DATA'))<Partial<FormData>>(),
  updateActiveFormData: createAction(creator('UPDATE_ACTIVE_FORM_DATA'))(),
  removeBaseImage: createAction(creator('REMOVE_BASE_IMAGE'))(),
  addBaseImage: createAction(creator('ADD_BASE_IMAGE'))<number>(),
  setBaseImage: createAction(creator('SET_BASE_IMAGE'))<number>(),
  generateImage: createAction(creator('GENERATE_IMAGE'))(),
  setOpenResultView: createAction(creator('OPEN_RESULT_VIEW'))<boolean>(),
  downloadAll: createAction(creator('DOWNLOAD_ALL'))(),
  loadAllResult: createAction(creator('LOAD_ALL_RESULT'))(),
  setScrollToTop: createAction(creator('SCROLL_TO_TOP'))<boolean>(),
  setGenerating: createAction(creator('SET_GENERATING'))<boolean>(),
  setGenerateStarting: createAction(creator('SET_GENERATE_STARTING'))<boolean>(),
  copyText: createAction(creator('COPY_TEXT'))<string>(),
  setRandomPrompt: createAction(creator('SET_RANDOM_PROMPT'))(),
  setRandomPromptLoading: createAction(creator('SET_RANDOM_PROMPT_LOADING'))<boolean>()
}

export const GENERATE_RESOLUTION_OPTIONS: SelectBaseItemType<string>[] =
  GENERATE_RESOLUTION_PAIR.map(value => ({
    label: `${value.width} x ${value.height} (${value.aspectRatio[0]}:${value.aspectRatio[1]})`,
    value: Utils.getResolutionText(value)
  }))

export const REFINEMENT_STEP_OPTIONS: SelectBaseItemType<number>[] = REFINEMENT_STEPS.map(
  value => ({
    label: `${value}`,
    value: _toNumber(value)
  })
)

export const INITIAL_FORM_DATA: FormData = {
  prompt: '',
  prompt_negative: '',
  seed: 0,
  image: undefined,
  samples: 1,
  resolution: GENERATE_RESOLUTION_OPTIONS[0].value,
  enhance_prompt: undefined,
  prompt_strength: 0.5,
  control_strength: 0.5
}

// Selectors
const selectSketchTextToImage = (state: RootState) => state.container.sketchTextToImagePage
const id = createSelector(apiSelectors.currentSketchTextToImageProjectId, id => id)

const formData = createSelector(
  selectSketchTextToImage,
  sketchTextToImagePage => sketchTextToImagePage.formData
)

const activeFormData = createSelector(
  selectSketchTextToImage,
  sketchTextToImagePage => sketchTextToImagePage.activeFormData
)

const currentFormData = createSelector(id, formData, (id, formData) =>
  id ? formData[id] : undefined
)

const currentActiveFormData = createSelector(activeFormData, activeFormData => activeFormData)

const selectCurrentImage = createSelector(
  currentFormData,
  apiSelectors.userImages,
  (formData, userImages) => ({
    imageFileOriginal: formData?.imageFileOriginal,
    imageFileCropped: formData?.imageFileCropped,
    imageFileEdit: formData?.imageFileEdit,
    image: userImages[formData?.image ?? 0]
  })
)

const selectIsGenerating = createSelector(
  selectSketchTextToImage,
  sketchTextToImagePage => sketchTextToImagePage.isGenerating
)
const selectIsGenerateStarting = createSelector(
  selectSketchTextToImage,
  sketchTextToImagePage => sketchTextToImagePage.isGenerateStarting
)
export const formDataSelectors = {
  currentImage: selectCurrentImage,
  currentFormData,
  currentActiveFormData,
  process: createSelector(currentFormData, formData => formData?.process),
  currentAnchor: createSelector(
    currentFormData,
    apiSelectors.sketchTextToImageOutputs,
    (formData, sketchTextToImageOutputs) =>
      formData?.seed !== INITIAL_FORM_DATA.seed
        ? sketchTextToImageOutputs[formData?.seed ?? 0]
        : undefined
  )
}

export const sketchTextToImageSelectors = {
  sketchCommand: createSelector(
    selectSketchTextToImage,
    sketchToImagePanel => sketchToImagePanel.sketchCommand
  ),
  maskCommand: createSelector(
    selectSketchTextToImage,
    sketchToImagePanel => sketchToImagePanel.maskCommand
  ),
  enableBackgroundTransform: createSelector(
    selectSketchTextToImage,
    sketchToImagePanel => sketchToImagePanel.enableBackgroundTransform
  ),
  randomPromptLoading: createSelector(
    selectSketchTextToImage,
    sketchToImagePanel => sketchToImagePanel.randomPromptLoading
  ),
  sketchMode: createSelector(
    selectSketchTextToImage,
    sketchToImagePanel => sketchToImagePanel.sketchMode
  ),
  openResultView: createSelector(selectSketchTextToImage, data => data.openResultView),
  scrollToTop: createSelector(selectSketchTextToImage, data => data.scrollToTop),
  skethTextToImage: selectSketchTextToImage,
  lastOutputRequest: createSelector(
    apiSelectors.currentSketchTextToImageProjectOutputList,
    outputList => outputList?.lastListReq
  ),
  generateLoading: createSelector(
    selectIsGenerating,
    apiSelectors.loading['sketchTextToImage.generateSTIProject'],
    apiSelectors.loading['sketchTextToImage.listSTIProjectOutput'],
    (isGenerating, generateSTIProjectLoading, listSTIProjectOutputLoading) =>
      Boolean(isGenerating || generateSTIProjectLoading) ||
      Boolean(listSTIProjectOutputLoading === LOAD_OUTPUT_AFTER_GENERATE_LOADING)
  ),
  isGenerateStarting: selectIsGenerateStarting,
  isGenerating: selectIsGenerating,
  generateCount: createSelector(
    selectSketchTextToImage,
    sketchTextToImagePage => sketchTextToImagePage.generateCount
  )
}

// Reducer
export type SketchTextToImageState = {
  id?: number
  sketchMode: 'sketch' | 'image'
  enableBackgroundTransform: boolean
  sketchCommand: SketchCommandType | null
  maskCommand: Command | null
  openResultView?: boolean
  isGenerating: boolean
  isGenerateStarting: boolean
  generateCount?: number
  scrollToTop: boolean
  formData: Record<number, FormData>
  activeFormData: Partial<FormData>
  randomPromptLoading: boolean
}

export const INITIAL: SketchTextToImageState = {
  id: undefined,
  sketchMode: 'image',
  enableBackgroundTransform: false,
  sketchCommand: null,
  maskCommand: null,
  isGenerateStarting: false,
  isGenerating: false,
  generateCount: 0,
  openResultView: false,
  scrollToTop: false,
  formData: {},
  activeFormData: {},
  randomPromptLoading: false
}

const reducer = produce((state: SketchTextToImageState, { type, payload }) => {
  switch (type) {
    case getType(sketchTextToImageActions.init): {
      const { id, prompt, prompt_negative, prompt_strength, control_strength } =
        payload as ActionType<typeof sketchTextToImageActions.init>['payload']

      state.id = id

      if (id) {
        state.formData = {
          ...state.formData,
          [id]: {
            ...(state.formData[id] ?? INITIAL_FORM_DATA),
            prompt: prompt ?? INITIAL_FORM_DATA.prompt,
            prompt_negative: prompt_negative ?? INITIAL_FORM_DATA.prompt_negative,
            prompt_strength:
              Math.round((prompt_strength ?? INITIAL_FORM_DATA.prompt_strength) * 10) / 10,
            control_strength:
              Math.round((control_strength ?? INITIAL_FORM_DATA.control_strength) * 10) / 10
          }
        }
      }

      return
    }
    case getType(sketchTextToImageActions.setRandomPromptLoading): {
      const loading = payload as ActionType<
        typeof sketchTextToImageActions.setRandomPromptLoading
      >['payload']

      state.randomPromptLoading = loading

      return
    }
    case getType(sketchTextToImageActions.setSketchTextProjectId): {
      const id = payload as ActionType<
        typeof sketchTextToImageActions.setSketchTextProjectId
      >['payload']

      state.id = id
      return
    }
    case getType(sketchTextToImageActions.setSketchMode): {
      const sketchMode = payload as ActionType<
        typeof sketchTextToImageActions.setSketchMode
      >['payload']

      state.sketchMode = sketchMode
      return
    }
    case getType(sketchTextToImageActions.setEnableBackgroundTransform): {
      const enableBackgroundTransform = payload as ActionType<
        typeof sketchTextToImageActions.setEnableBackgroundTransform
      >['payload']

      state.enableBackgroundTransform = enableBackgroundTransform
      return
    }
    case getType(sketchTextToImageActions.setOpenResultView): {
      const openResultView = payload as ActionType<
        typeof sketchTextToImageActions.setOpenResultView
      >['payload']

      state.openResultView = openResultView
      return
    }
    case getType(sketchTextToImageActions.setSketchCommand): {
      const sketchCommand = payload as ActionType<
        typeof sketchTextToImageActions.setSketchCommand
      >['payload']
      state.sketchCommand = sketchCommand
      return
    }
    case getType(sketchTextToImageActions.setMaskCommand): {
      const maskCommand = payload as ActionType<
        typeof sketchTextToImageActions.setMaskCommand
      >['payload']
      state.maskCommand = maskCommand
      return
    }
    case getType(sketchTextToImageActions.updateFormData): {
      const id = state.id
      const formData = payload as ActionType<
        typeof sketchTextToImageActions.updateFormData
      >['payload']

      if (id) {
        state.formData = {
          ...state.formData,
          [id]: {
            ...(state.formData[id] ?? INITIAL_FORM_DATA),
            ...formData
          }
        }
      }
      return
    }
    case getType(sketchTextToImageActions.updateActiveFormData): {
      const id = state.id

      if (id) {
        state.activeFormData = {
          ...state.formData[id]
        }
      }

      return
    }
    case getType(sketchTextToImageActions.removeBaseImage): {
      const id = state.id

      if (id) {
        state.formData = {
          ...state.formData,
          [id]: {
            ...(state.formData[id] ?? INITIAL_FORM_DATA),
            image: undefined,
            imageFileOriginal: undefined,
            imageFileCropped: undefined,
            imageFileEdit: undefined
          }
        }
      }
      return
    }
    case getType(sketchTextToImageActions.setBaseImage): {
      const id = state.id
      const baseImage = payload as ActionType<
        typeof sketchTextToImageActions.setBaseImage
      >['payload']

      if (id) {
        state.formData = {
          ...state.formData,
          [id]: {
            ...(state.formData[id] ?? INITIAL_FORM_DATA),
            image: baseImage,
            imageFileOriginal: undefined,
            imageFileCropped: undefined,
            imageFileEdit: undefined
          }
        }
      }
      return
    }
    case getType(sketchTextToImageActions.setGenerating): {
      const generating = payload as ActionType<
        typeof sketchTextToImageActions.setGenerating
      >['payload']

      state.isGenerating = generating
      state.generateCount = generating
        ? (state.generateCount ?? 0) + GENERATING_POOL_INTERVAL / 1000
        : undefined
      return
    }
    case getType(sketchTextToImageActions.setGenerateStarting): {
      const generating = payload as ActionType<
        typeof sketchTextToImageActions.setGenerateStarting
      >['payload']

      state.isGenerateStarting = generating
      return
    }
    case getType(sketchTextToImageActions.setScrollToTop): {
      const scrollToTop = payload as ActionType<
        typeof sketchTextToImageActions.setScrollToTop
      >['payload']

      state.scrollToTop = scrollToTop
      return
    }
  }
}, INITIAL)

// Epics
const createSketchTextProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(sketchTextToImageActions.createSketchTextProject)),
    map(({ payload }) => ({
      name: payload.name,
      genre: payload.genre
    })),
    filter(({ name, genre }) => Boolean(name) && !!genre),
    mergeMap(param =>
      merge(
        of(param).pipe(
          switchMap(({ name, genre }) =>
            action$.pipe(
              filter(isActionOf(apiActions.sketchTextToImage.createSTIProjectResponse)),
              take(1),
              map(({ payload }) => ({
                id: payload.id,
                data: payload
              })),
              tap(({ data }) => {
                MixPanelUtils.track<'PROJECT__CREATE'>(
                  'Project - Create',
                  DataUtils.getProjectParam<'ST2I_project'>('ST2I_project', {
                    sketchTextToImageProject: data
                  })
                )
                FacebookPixelUtils.track<'CREATE_SKETCH_TEXT_TO_IMAGE'>('create_st2i')
              }),
              map(({ id }) => replace(`${route.SKETCHTEXT_TO_IMAGE_PROJECTS.getUrl({ id })}`)),
              startWith(
                apiActions.sketchTextToImage.createSTIProject({
                  name,
                  genre
                })
              )
            )
          )
        ),
        of(sketchTextToImageActions.setSketchTextProjectId(undefined))
      )
    )
  )

const generateImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(sketchTextToImageActions.generateImage)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action,
      state,
      canTrainOrHasBalance: userSelectors.canTrainOrHasBalance(state) ?? true
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ canTrainOrHasBalance }) => !canTrainOrHasBalance),
          mergeMap(() => [
            bannerActions.upsell.show({
              dismissable: true,
              upsellLocation: 'sketch',
              contentMode: 'used-up',
              position: 'float-bottom-banner-overlay'
            }),
            sketchTextToImageActions.setGenerateStarting(false)
          ])
        ),
        of(param).pipe(
          filter(({ canTrainOrHasBalance }) => Boolean(canTrainOrHasBalance)),
          map(({ state }) => ({
            formData: currentFormData(state),
            genreData: apiSelectors.sketchTextGenreData(state),
            currentSketchTextToImageProject: apiSelectors.currentSketchTextToImageProject(state)
          })),
          map(({ currentSketchTextToImageProject, formData = INITIAL_FORM_DATA, genreData }) => {
            const id = currentSketchTextToImageProject?.id ?? 0
            const genre = currentSketchTextToImageProject?.genre
            const generate_api_url = genreData[genre ?? 0]?.generate_api_url
            const isValid = !!id

            const generateResolution = Utils.getGenerateParamFromText(formData?.resolution)

            const generateParam: SketchTextGenerateReq | undefined = isValid
              ? {
                  id,
                  generate_api_url,
                  width: generateResolution?.width ?? BASE_GENERATE_SIZE,
                  height: generateResolution?.height ?? BASE_GENERATE_SIZE,
                  process: formData.process,
                  model: formData.model,
                  image: formData.sketch,
                  mask: formData.mask,
                  prompt: formData.prompt ?? '',
                  prompt_negative: formData.prompt_negative ?? '',

                  //advanced options
                  seed: formData.seed,
                  samples: formData.samples,
                  enhance_prompt: formData.enhance_prompt,
                  prompt_strength: formData.prompt_strength,
                  control_strength: formData.control_strength
                }
              : undefined

            const errorState = {
              hasError: false,
              message: ''
            }

            const requiredPrompt = PROMPT_REQUIRED.includes(formData.process ?? '')
            const requiredImage = IMAGE_REQUIRED.includes(formData.process ?? '')
            const requireMask = MASK_REQUIRED.includes(formData.process ?? '')

            const image = formData.imageFileCropped ?? formData.imageFileOriginal

            const hasImage = Boolean(image || currentSketchTextToImageProject?.input_img?.file)

            if (!(formData.prompt || formData.prompt_negative) && requiredPrompt) {
              errorState.hasError = true
              errorState.message = 'Please fill prompt or negative prompt to generate.'
            }

            if (!hasImage && requiredImage) {
              errorState.hasError = true
              errorState.message = 'Please upload an image.'
            }

            if (!hasImage && requiredImage) {
              errorState.hasError = true
              errorState.message = 'Please upload an image.'
            }

            if (formData.isMaskEmpty && requireMask) {
              errorState.hasError = true
              errorState.message =
                'To use Mask In/Out please use the MASK tool to mark an area in them image to be Masked In (Kept) or Masked Out (Replace).'
            }

            return {
              generateParam,
              errorState,
              hasMaskAndSketch: !!generateParam?.mask && !!generateParam?.image,
              updateParam: { id, input_data: formData.input_data }
            }
          }),
          filter(({ generateParam }) => {
            return !!generateParam
          }),
          mergeMap(({ updateParam, hasMaskAndSketch, generateParam, errorState }) =>
            merge(
              of({ errorState }).pipe(
                filter(({ errorState }) => errorState.hasError),
                mergeMap(({ errorState }) => [
                  sketchTextToImageActions.setGenerateStarting(false),
                  dialogActions.addDialog({
                    [ErrorDialog.ERROR]: {
                      dialogName: ErrorDialog.ERROR,
                      title: `Unable to generate`,
                      content: [errorState.message]
                    }
                  })
                ])
              ),
              of({ updateParam, hasMaskAndSketch, generateParam, errorState }).pipe(
                filter(
                  ({ hasMaskAndSketch, errorState }) => hasMaskAndSketch && !errorState.hasError
                ),
                mergeMap(({ updateParam, generateParam }) =>
                  action$.pipe(
                    filter(isActionOf(apiActions.sketchTextToImage.updateSTIProjectResponse)),
                    take(1),
                    mergeMap(() => [
                      sketchTextToImageActions.updateFormData({
                        sketch: undefined,
                        mask: undefined,
                        input_data: undefined
                      }),
                      apiActions.sketchTextToImage.generateSTIProject(
                        generateParam as unknown as SketchTextGenerateReq
                      )
                    ]),
                    startWith(apiActions.sketchTextToImage.updateSTIProject({ data: updateParam }))
                  )
                )
              )
            )
          )
        )
      )
    )
  )

const downloadAllEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([sketchTextToImageActions.downloadAll, sketchTextToImageActions.loadAllResult])
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      outputs: apiSelectors.currentSketchTextToImageProject(state)?.outputs ?? [],
      currentTextToImageProjectId: apiSelectors.currentTextToImageProjectId(state),
      lastRequest: sketchTextToImageSelectors.lastOutputRequest(state)
    })),
    mergeMap(({ lastRequest, currentTextToImageProjectId = 0, outputs }) =>
      merge(
        of({ lastRequest, currentTextToImageProjectId }).pipe(
          filter(({ lastRequest, currentTextToImageProjectId }) =>
            Boolean(lastRequest && lastRequest.next && currentTextToImageProjectId)
          ),
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.textToImage.listTIProjectOutputResponse)),
              take(1),
              map(() => sketchTextToImageActions.loadAllResult()),
              startWith(
                apiActions.textToImage.listTIProjectOutput({
                  param: { project: currentTextToImageProjectId, limit: 30 },
                  next: true
                })
              )
            )
          )
        ),
        of(lastRequest).pipe(
          filter(lastRequest => Boolean(lastRequest && lastRequest.next)),
          map(() =>
            snackBarActions.show({
              content: `Load all Sketch Text To Art results...`,
              actionText: `${outputs.length} / ${lastRequest?.count}`
            })
          )
        ),
        of(lastRequest).pipe(
          filter(lastRequest => !Boolean(lastRequest && lastRequest.next)),
          withLatestFrom(state$),
          map(([_, state]) => ({
            textToImageProjectName: apiSelectors.currentTextToImageProject(state)?.name ?? '',
            outputs: apiSelectors.currentProArtFilterProject(state)?.outputs ?? []
          })),
          map(({ outputs, textToImageProjectName }) => ({
            files: outputs.map(output => ({
              fileUrl: output?.image?.file ?? '',
              imageName: `text-to-art-result_${textToImageProjectName}_${
                output?.image?.id
              }.${UrlUtils.getFileTypeFromUrl(output?.image?.file ?? '')}`
            })),
            textToImageProjectName
          })),
          map(({ files, textToImageProjectName }) =>
            downloaderActions.multiple.executeDownloadMultipleImage({
              files,
              fileName: `text-to-art_${textToImageProjectName}`
            })
          )
        )
      )
    )
  )

/* 

- Listen when upload list received
  - When failed, the status will become UNKNOWN
  - So when generate pooling is already running in sometimes, 
    then status become UNKNOWN, then stop the pooling
*/

const listenOnRetrieveSketchTextToImageEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf([
        apiActions.sketchTextToImage.retrieveSTIProjectResponse,
        apiActions.sketchTextToImage.generateSTIProjectResponse
      ])
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      isCurrentUserFree: apiSelectors.isCurrentUserFree(state),
      isGenerating: sketchTextToImageSelectors.isGenerating(state),
      generateCount: sketchTextToImageSelectors.generateCount(state),
      currentSketchTextToImageProject: apiSelectors.currentSketchTextToImageProject(state),
      id: action.payload.id
    })),
    map(
      ({
        isGenerating,
        generateCount = 0,
        currentSketchTextToImageProject,
        id,
        isCurrentUserFree
      }) => {
        const currentSketchTextToImageProjectId = currentSketchTextToImageProject?.id ?? 0
        const generate_num = currentSketchTextToImageProject?.generate_num ?? undefined
        const status = currentSketchTextToImageProject?.status
        const expect_finished = currentSketchTextToImageProject?.expect_finished ?? 0
        const queue_length = currentSketchTextToImageProject?.queue_length ?? 0

        const hasQueue = !!(
          expect_finished && generateCount < expect_finished + ADDITIONAL_THRESHOLD_GENERATE
        )

        //https://app.asana.com/0/1199618138501774/1205828874243105/f
        //https://app.asana.com/0/1199618138501774/1205813350920393/f
        const isGeneratingButFinished = isGenerating && status === 'FINISHED' && generate_num !== 0
        const isGeneratingButFinishedError =
          isGenerating && status === 'FINISHED' && generate_num === 0

        const shouldContinueGenerateUnknownStatus = hasQueue && status === 'UNKNOWN'
        const isProcessing = status === 'PROCESSING'

        const shouldShowTimeoutDialog =
          isGenerating &&
          !shouldContinueGenerateUnknownStatus &&
          !isProcessing &&
          !isGeneratingButFinished &&
          !isGeneratingButFinishedError

        const shouldShowErrorDialog = isGenerating && isGeneratingButFinishedError

        return {
          errorExtra: {
            elapsed_pooling: generateCount,
            expect_finished,
            queue_length
          },
          isCurrentUserFree,
          currentSketchTextToImageProjectId,
          id,
          isGeneratingButFinished,
          shouldShowTimeoutDialog,
          shouldContinueGenerateUnknownStatus,
          isProcessing,
          shouldShowErrorDialog
        }
      }
    ),
    tap(({ shouldShowErrorDialog, errorExtra }) => {
      if (shouldShowErrorDialog) {
        SentryUtils.captureMessage(
          `Unable To Generate Text To Image in expected time`,
          errorExtra,
          'error'
        )
      }
    }),
    filter(({ currentSketchTextToImageProjectId, id }) => currentSketchTextToImageProjectId === id),
    mergeMap(
      ({
        currentSketchTextToImageProjectId,
        isGeneratingButFinished,
        shouldContinueGenerateUnknownStatus,
        isProcessing,
        shouldShowErrorDialog,
        shouldShowTimeoutDialog,
        isCurrentUserFree
      }) =>
        _compact([
          sketchTextToImageActions.setGenerating(
            shouldContinueGenerateUnknownStatus || isProcessing
          ),
          sketchTextToImageActions.setGenerateStarting(
            shouldContinueGenerateUnknownStatus || isProcessing
          ),
          isGeneratingButFinished
            ? apiActions.sketchTextToImage.listSTIProjectOutput({
                param: { project: currentSketchTextToImageProjectId, limit: 30 },
                loadingMessage: LOAD_OUTPUT_AFTER_GENERATE_LOADING
              })
            : null,
          isGeneratingButFinished
            ? apiActions.sketchTextToImage.resetSketchTextToImageQueueData(
                currentSketchTextToImageProjectId
              )
            : null,
          isGeneratingButFinished && isCurrentUserFree ? apiActions.users.retrieveEquity() : null,
          isGeneratingButFinished ? sketchTextToImageActions.setScrollToTop(true) : null,
          shouldShowTimeoutDialog
            ? dialogActions.openDialog({
                [ErrorDialog.ERROR]: {
                  dialogName: ErrorDialog.ERROR,
                  title: 'The generation is taking more time than expected',
                  content: [
                    `When generation is done they will appear in your project.`,
                    `if this keeps happening, contact us by click on the "Question" button or send an email to ${values.PLAYFORM_CONTACT_EMAIL}.`
                  ]
                }
              })
            : null,
          shouldShowErrorDialog
            ? dialogActions.openDialog({
                [ErrorDialog.ERROR]: {
                  dialogName: ErrorDialog.ERROR,
                  title: 'Failed to Generate',
                  content: [
                    `Please try a different model or process, or simply try again`,
                    `if this keeps happening, contact us by click on the "Question" button or send an email to ${values.PLAYFORM_CONTACT_EMAIL}.`
                  ]
                }
              })
            : null
        ])
    )
  )

const uploadSketchEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(sketchTextToImageActions.uploadSketch)),
    mergeMap(({ payload }) =>
      action$.pipe(
        filter(isActionOf(apiActions.sketchToImage.uploadSketchResponse)),
        take(1),
        map(() => sketchTextToImageActions.setSketchCommand('resetBackground')),
        startWith(apiActions.sketchTextToImage.uploadSketch(payload))
      )
    )
  )

const listenRetrieveSketchTextToImageErrorEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    filter(
      ({ payload }) => payload.type === getType(apiActions.sketchTextToImage.retrieveSTIProject)
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      error: action.payload,
      isGenerating: sketchTextToImageSelectors.isGenerating(state)
    })),
    mergeMap(({ isGenerating, error }) =>
      _compact([
        sketchTextToImageActions.setGenerating(false),
        sketchTextToImageActions.setGenerateStarting(false),
        isGenerating &&
          dialogActions.addDialog({
            [ErrorDialog.ERROR]: {
              dialogName: ErrorDialog.ERROR,
              title: `Unable to retrieve Sketch Text To Art Project data`,
              content: [
                errorUtils.flattenMessage(error).toString(),
                'Generating process is still working in the background, the result will be available when it finished. Try to refresh this page to see the finished results'
              ]
            }
          })
      ])
    )
  )

const listenRetrieveSketchTextGenerateErrorEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    filter(
      ({ payload }) => payload.type === getType(apiActions.sketchTextToImage.generateSTIProject)
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      error: action.payload
    })),
    mergeMap(({ error }) => [
      sketchTextToImageActions.setGenerating(false),
      sketchTextToImageActions.setGenerateStarting(false),
      dialogActions.addDialog({
        [ErrorDialog.ERROR]: {
          dialogName: ErrorDialog.ERROR,
          title: `Unable To Generate Art`,
          content: [errorUtils.flattenMessage(error).toString()]
        }
      })
    ])
  )

const setScrollToTopEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(sketchTextToImageActions.setScrollToTop)),
    filter(({ payload }) => Boolean(payload)),
    delay(1000),
    map(() => sketchTextToImageActions.setScrollToTop(false))
  )

const setCurrentTextToImageProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.setCurrentSketchTextToImageProject)),
    filter(({ payload }) => Boolean(payload)),
    mergeMap(param =>
      merge(
        of(param).pipe(
          switchMap(({ payload = 0 }) =>
            action$.pipe(
              filter(isActionOf(apiActions.sketchTextToImage.retrieveSTIProjectResponse)),
              take(1),
              map(({ payload }) => sketchTextToImageActions.init(payload)),
              startWith(apiActions.sketchTextToImage.retrieveSTIProject(payload))
            )
          )
        ),
        of(sketchTextToImageActions.setSketchTextProjectId(param.payload))
      )
    )
  )

const retrieveTIProjectListener: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.textToImage.retrieveTIProjectResponse)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      payload: action.payload,
      currentProject: apiSelectors.currentTextToImageProject(state)
    })),
    filter(({ payload, currentProject }) => payload.id === currentProject?.id),
    map(({ currentProject }) =>
      sketchTextToImageActions.updateFormData({ image: currentProject?.image?.id })
    )
  )

const copyTextEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(sketchTextToImageActions.copyText)),
    mergeMap(({ payload }) =>
      from(navigator.clipboard.writeText(payload)).pipe(
        mergeMap(param =>
          merge(
            of(snackBarActions.show({ content: 'The input text copied into your clipboard!' })),
            of(param).pipe(
              delay(1500),
              map(() => snackBarActions.close())
            )
          )
        )
      )
    )
  )

const addBaseImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(sketchTextToImageActions.addBaseImage)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      adjustedImages: apiSelectors.adjustedImages(state),
      imageId: action.payload,
      userImages: apiSelectors.userImages(state)
    })),
    map(({ adjustedImages, imageId, userImages }) => {
      const imageData = userImages[imageId]
      const adjustedImage = adjustedImages[imageData?.adjust ?? 0]
      return {
        imageData,
        adjustedImage,
        hasAdjustedImageNotFetched: !!imageData.adjust && !adjustedImage,
        hasAdjustedImageFetched: !!imageData.adjust && !!adjustedImage,
        noAdjustedImage: !imageData.adjust
      }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ hasAdjustedImageNotFetched }) => hasAdjustedImageNotFetched),
          mergeMap(({ imageData }) =>
            action$.pipe(
              filter(isActionOf(apiActions.imageEnhancement.retrieveAdjustedImageResponse)),
              take(1),
              map(({ payload }) => sketchTextToImageActions.setBaseImage(payload?.output?.id ?? 0)),
              startWith(
                apiActions.imageEnhancement.retrieveAdjustedImage({
                  adjustedImageId: imageData.adjust ?? 0
                })
              )
            )
          )
        ),
        of(param).pipe(
          filter(({ hasAdjustedImageFetched }) => hasAdjustedImageFetched),
          map(({ adjustedImage }) =>
            sketchTextToImageActions.setBaseImage(adjustedImage?.output?.id ?? 0)
          )
        ),
        of(param).pipe(
          filter(({ noAdjustedImage }) => noAdjustedImage),
          map(({ imageData }) => sketchTextToImageActions.setBaseImage(imageData?.id ?? 0))
        )
      )
    )
  )

const setRandomPrompt: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(sketchTextToImageActions.setRandomPrompt)),
    switchMap(() =>
      merge(
        of(sketchTextToImageActions.setRandomPromptLoading(true)),
        ApiUtils.sketchTextToImage.retrieveRandomPrompt().pipe(
          mergeMap(response => [
            sketchTextToImageActions.setRandomPromptLoading(false),
            sketchTextToImageActions.updateFormData({ prompt: response.data.text })
          ]),
          catchError(err => of(sketchTextToImageActions.setRandomPromptLoading(false)))
        )
      )
    )
  )

export const listenOnGenerateError: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    mergeMap(({ payload }) =>
      merge(
        of(payload).pipe(
          filter(
            payload => payload.type === getType(apiActions.sketchTextToImage.generateSTIProject)
          ),
          mergeMap(errorBundle => [
            sketchTextToImageActions.setGenerateStarting(false),
            sketchTextToImageActions.setGenerating(false),
            dialogActions.addDialog({
              [ErrorDialog.ERROR]: {
                dialogName: ErrorDialog.ERROR,
                title: `Unable to generate`,
                content: errorUtils.flattenMessage(errorBundle)
              }
            })
          ])
        )
      )
    )
  )

export const epics = combineEpics(
  listenOnGenerateError,
  generateImageEpic,
  setRandomPrompt,
  uploadSketchEpic,
  createSketchTextProjectEpic,
  addBaseImageEpic,
  copyTextEpic,
  setCurrentTextToImageProjectEpic,
  setScrollToTopEpic,
  listenRetrieveSketchTextToImageErrorEpic,
  listenRetrieveSketchTextGenerateErrorEpic,
  listenOnRetrieveSketchTextToImageEpic,
  downloadAllEpic,
  retrieveTIProjectListener
)

export default reducer

import { AxiosResponse } from 'axios'
import { XOR } from 'ts-xor'
import { SelectedFormat, SelectDirection } from 'utils/DataProcessingUtils'
import { SketchGuideType } from 'components/Sketch/Guides'
import { BannerSubscriptionUpsellProps } from 'components/Banner/BannerSubscriptionUpsell'

export const EngineConfigKeyList = [
  'morph',
  'freeform',
  'diffusion_freeform',
  'progressive',
  'style_transfer',
  'morph_512',
  'freeform_high_res',
  'freeform_high_res_2',
  'freeform_high_res_v2'
] as const

export const EngineConfigFreeformList: EngineConfigKeyType[] = [
  'freeform',
  'freeform_high_res',
  'freeform_high_res_2',
  'freeform_high_res_v2'
]

export const EngineConfigCreativeMorphList: EngineConfigKeyType[] = ['morph_512', 'morph']

export const MixGenreKeyList = ['face'] as const
export const SketchGenreKeyList = [
  'still_life_2',
  'abstract',
  'fashion',
  'human_face',
  'amorphous_face',
  'still_life',
  'landscape'
] as const

export const ProArtFilterGenreKeyList = ['nst', 'mrf'] as const

export type EngineConfigKeyType = (typeof EngineConfigKeyList)[number]
export type MixGenreKeyType = (typeof MixGenreKeyList)[number]
export type SketchGenreKeyType = (typeof SketchGenreKeyList)[number]
export type ProArtFilterGenreKeyType = (typeof ProArtFilterGenreKeyList)[number]

export type ObjectType = 'user_image'

export type ConfigVariables = {
  cost_per_atx_credit: number
  training_minutes_per_atx_credit: number
  download_image_atx_credit: number
  remove_watermark: { [key: string]: number }
  buy_credit_increment: number
  coinbase_minimum_amount: number
  phone_validation_credit: number
}
export interface ImageType {
  id: number
  file: string
  width: number
  height: number
  thumbnail?: string | null
  created?: string
}

export type AdjustedImage = {
  id: number
  user: User
  image: UserImage | null
  output: UserImage
  controls: {
    contrast: number
    brightness: number
    saturation: number
    exposure: number
    temperature: number
    tint: number
    smooth: number
    auto_contrast: boolean
    deblocking: boolean
  }
  created: string
  modified: string
}

export type AdjustedImageControl = keyof AdjustedImage['controls']

export type DownloadImage = {
  id: number
  created: string
  image: UserImage
}
export interface TaskQueue<T = 'UNKNOWN' | 'PROCESSING' | 'FINISHED'> {
  //Extra Field on Detail API and generate
  status?: T
  generate_num?: number

  expect_finished?: number
  queue_length?: number
}
export interface Bookmarkable<T extends BookmarkType> {
  bookmark: number | null

  //Derived state
  bookmarkData?: Bookmark<T>
}
export interface Shareable {
  object_type: 'user_image'
}

export interface Shareable {
  object_type: 'user_image'
}

export interface Adjustable {
  adjust: number | null

  //Derived state
  adjustData?: AdjustedImage
}

export interface Downloadable {
  download: number | null

  //Derived state
  downloadData?: DownloadImage
}

export type UserImage = ImageType &
  Bookmarkable<'user-image'> &
  Shareable &
  Adjustable &
  Downloadable & {
    is_published: boolean
  }

export type ProcessUIDataItem = {
  file: string
  width: number
  height: number
  name: string
}

export const ProcessGroupList = [
  'mix',
  'sketch',
  'train',
  'style_transfer',
  'text_to_image',
  'text_to_video'
] as const

export type ProcessGroupType = (typeof ProcessGroupList)[number]
export const ProcessTypeList = [
  ...EngineConfigKeyList,
  ...MixGenreKeyList,
  ...SketchGenreKeyList,
  ...ProArtFilterGenreKeyList
] as const

export type ProcessType = (typeof ProcessTypeList)[number]

export interface ProcessUIData {
  thumbnail: ProcessUIDataItem
  video?: Pick<ProcessUIDataItem, 'name' | 'file'> & { subtitle: string; thumbnail: string }
  samples: ProcessUIDataItem[]

  icon?: React.FC<any>
  descriptions?: string[]
  img_src?: string
  thumbnail_description?: string
}

export type ApiErrorBody = AxiosResponse<any>

export type UserUiExtrasType = {
  is_saw_collection_remove_confirm?: boolean
  is_seen_public_project_welcome?: boolean
  is_saw_welcome_slider?: boolean
  is_did_initial_project_creation?: boolean
  is_did_subscribe_plus?: boolean
  is_did_subscribe_pro?: boolean
  is_did_subscribe_studio?: boolean
  has_redeem_free_credit?: boolean
  has_checked_text_to_image_agreement?: boolean
}

export const SocialList = ['facebook', 'instagram', 'twitter', 'linkedin'] as const
export type SocialType = (typeof SocialList)[number]

export const SOCIAL_URL_LABEL: Record<SocialType, string> = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  twitter: 'Twitter',
  linkedin: 'Linkedin'
}

export type UploadPhotoType = 'avatar' | 'cover'

export type User = {
  id: string
  username: string
  first_name: string
  last_name: string
  email: string
  portfolio?: string
  is_agree_toc: boolean | null
  ui_extras?: UserUiExtrasType
  is_staff: boolean
  phone: string
  can_debug_user: boolean

  social_urls: { [key in SocialType]?: string }
  alias: string
  description: string

  is_receive_news: boolean | null
  is_receive_social_update: boolean | null
  is_receive_project_update: boolean | null
  is_receive_management: boolean | null
} & Record<UploadPhotoType, UserImage | undefined>

export type CheckPhoneUsedReq = {
  phone: string
}
export type UpdatePhoneResponse = {
  phone: string
  send_credits: boolean
}
export const EquityTypeList = ['free', 'plus', 'pro', 'studio'] as const

export type EquityType = (typeof EquityTypeList)[number]
export const EQUITY_WITH_MONTHLY_CREDIT: EquityType[] = ['pro', 'studio']
export const EQUITY_WITH_ADVANCED_TRAINING_CONTROL: EquityType[] = ['pro', 'studio']

export type DownloadPricing = {
  creditBalance: number
  price: number
  endBalance: number
  hasEnoughBalance: boolean
}

export type Equity = {
  type: EquityType
  cost_per_credit: number //Cost in cent $ to buy 60 credit
  storage_limit: number
  bulk_download: boolean
  upscale_image: boolean
  pre_train_clip_limit: number //0 FOR UNLIMITED
  pre_train_clip_usage: number
  pre_train_limit: number //0 for unlimited
  pre_train_usage?: number
  expire: string
  advance_control: boolean
  upload_image_limit: number
  canceled?: boolean
  interval?: 'monthly' | 'yearly' | 'hourly'
  storage_usage: {
    sketch: number
    project: number
    lse: number
  }

  //Derived variable
  storage_usage_total?: number
  typeName?: string
  typeTitle?: string
  typeSubtitle?: string
  preTrainLeft: number
  preTrainLeftAlmostEmpty: boolean
  preTrainClipLeft: number
  preTrainClipLeftAlmostEmpty: boolean
  upsellState?: {
    canAccessST2IAdvanceOptions: boolean
    canAccessT2IAdvanceOptions: boolean
    canUpscale: boolean
    canBatchDownload: boolean
    canTrain: boolean
    canGenerateClip: boolean
    canUseAIAppSubscription: boolean
    canGenerateMixVideo: boolean
    showBanner: boolean
    contentMode: BannerSubscriptionUpsellProps['contentMode']
  }
}

export type EquityConfig = Pick<
  Equity,
  | 'bulk_download'
  | 'cost_per_credit'
  | 'pre_train_clip_limit'
  | 'pre_train_limit'
  | 'storage_limit'
  | 'type'
  | 'upscale_image'
  | 'typeName'
  | 'typeTitle'
  | 'typeSubtitle'
> & {
  monthly_credit: number
}

export type StorageUsageCategory = keyof Equity['storage_usage']

export const PERMISSION_LIST = [
  'account.add_emailaddress',
  'account.add_emailconfirmation',
  'account.change_emailaddress',
  'account.change_emailconfirmation',
  'account.delete_emailaddress',
  'account.delete_emailconfirmation',
  'account.view_emailaddress',
  'account.view_emailconfirmation',
  'admin.add_logentry',
  'admin.change_logentry',
  'admin.delete_logentry',
  'admin.view_logentry',
  'auth.add_group',
  'auth.add_permission',
  'auth.change_group',
  'auth.change_permission',
  'auth.delete_group',
  'auth.delete_permission',
  'auth.view_group',
  'auth.view_permission',
  'authtoken.add_token',
  'authtoken.change_token',
  'authtoken.delete_token',
  'authtoken.view_token',
  'contenttypes.add_contenttype',
  'contenttypes.change_contenttype',
  'contenttypes.delete_contenttype',
  'contenttypes.view_contenttype',
  'oneiro.add_dream',
  'oneiro.add_dreamimage',
  'oneiro.add_dreamimageset',
  'oneiro.add_dreamtask',
  'oneiro.add_userimage',
  'oneiro.add_userimageset',
  'oneiro.change_dream',
  'oneiro.change_dreamimage',
  'oneiro.change_dreamimageset',
  'oneiro.change_dreamtask',
  'oneiro.change_userimage',
  'oneiro.change_userimageset',
  'oneiro.delete_dream',
  'oneiro.delete_dreamimage',
  'oneiro.delete_dreamimageset',
  'oneiro.delete_dreamtask',
  'oneiro.delete_userimage',
  'oneiro.delete_userimageset',
  'oneiro.receive_task_summary',
  'oneiro.view_dream',
  'oneiro.view_dreamimage',
  'oneiro.view_dreamimageset',
  'oneiro.view_dreamtask',
  'oneiro.view_userimage',
  'oneiro.view_userimageset',
  'playform.add_collection',
  'playform.add_image',
  'playform.add_inputimageset',
  'playform.add_outputimage',
  'playform.add_outputimageset',
  'playform.add_project',
  'playform.add_projecttask',
  'playform.change_collection',
  'playform.change_image',
  'playform.change_inputimageset',
  'playform.change_outputimage',
  'playform.change_outputimageset',
  'playform.change_project',
  'playform.change_projecttask',
  'playform.create_freeform_project',
  'playform.delete_collection',
  'playform.delete_image',
  'playform.delete_inputimageset',
  'playform.delete_outputimage',
  'playform.delete_outputimageset',
  'playform.delete_project',
  'playform.delete_projecttask',
  'playform.view_collection',
  'playform.view_image',
  'playform.view_inputimageset',
  'playform.view_outputimage',
  'playform.view_outputimageset',
  'playform.view_project',
  'playform.view_projecttask',
  'sessions.add_session',
  'sessions.change_session',
  'sessions.delete_session',
  'sessions.view_session',
  'sites.add_site',
  'sites.change_site',
  'sites.delete_site',
  'sites.view_site',
  'survey.add_surveyanswer',
  'survey.add_surveytemplate',
  'survey.change_surveyanswer',
  'survey.change_surveytemplate',
  'survey.delete_surveyanswer',
  'survey.delete_surveytemplate',
  'survey.view_surveyanswer',
  'survey.view_surveytemplate',
  'users.add_user',
  'users.change_user',
  'users.delete_user',
  'users.view_user',
  'users.is_staff'
] as const

export type PermissionType = (typeof PERMISSION_LIST)[number]
export type PermissionsType = { [key in PermissionType]?: boolean }

export const ScopeList = ['current_user', 'public', 'bookmark'] as const
export type Scope = (typeof ScopeList)[number]

export type UpdateUserReq = Partial<
  Pick<
    User,
    | 'first_name'
    | 'ui_extras'
    | 'last_name'
    | 'portfolio'
    | 'social_urls'
    | 'description'
    | 'is_agree_toc'
    | 'is_receive_news'
    | 'is_receive_social_update'
    | 'is_receive_project_update'
    | 'is_receive_management'
  >
>

export type UpdateUserAliasReq = {
  alias: string
}

export type UploadUserPictureReq = { file: File; type: UploadPhotoType }[]

export type UpdateEmailSubReq = Pick<
  UpdateUserReq,
  | 'is_receive_news'
  | 'is_receive_social_update'
  | 'is_receive_project_update'
  | 'is_receive_management'
>

export type ChangeEmailReq = {
  email: string
  cb: string //callback url
  secret: string
}

export type ChangeEmailResponse = {
  status: string
  error_msg: string
}

export type VerifyReq = {
  cb: string
}

export type TrainProjectStatus =
  | 'drafted'
  | 'waiting'
  | 'processing'
  | 'ready'
  | 'stopped'
  | 'failed'

export const StopableProjectStatus: Array<TrainProjectStatus> = ['waiting', 'processing']
export const ActiveProjectStatus: Array<TrainProjectStatus> = ['waiting', 'processing']
export const InProgressProjectStatus: Array<TrainProjectStatus> = [
  'waiting',
  'processing',
  'stopped'
]
export const FinishedProjectStatus: Array<TrainProjectStatus> = ['ready']
export const ContinueAbleProjectStatus: Array<TrainProjectStatus> = ['ready', 'stopped']

export type ProjectUiExtrasType = {
  is_finish_checked?: boolean
  aesthetic_background_strip?: string
  inspiration_background_strip?: string
  is_generated_opened?: boolean
  is_has_clip?: boolean
}

export type TrainProjectProgress = {
  started_at: string
  percent: number
  minutes: number
}
export type TrainProject = Readonly<{
  // Server Data
  id: number
  name: string
  user: Pick<User, 'email' | 'first_name' | 'id' | 'last_name' | 'alias'>
  is_private: boolean
  category: string
  thumbnail: UserImage
  inspiration: number | null
  aesthetic: number | null
  ui_extras?: ProjectUiExtrasType
  created: string
  modified: string
  progress: TrainProjectProgress
  status: TrainProjectStatus
  mix: number | null
  has_noises: boolean
  object_type: ProjectObjectType
  total_snapshot: number
  current_snapshot: number
  outputs_count: number
  bookmark_scope: string

  // Derived Data
  outputs?: UserImage[]
  aestheticData?: InputImageSet | null
  inspirationData?: InputImageSet | null
  isProjectOwner?: boolean
  categoryName?: string
  mixData?: ProjectMix
}>

export type ProjectCopyReq = {
  project: number
  name: string
}

export const Details = [0.5, 1, 2] as const
export type DetailsType = (typeof Details)[number]
export const DetailsLabel: { [key in DetailsType]: string } = {
  0.5: 'low',
  1: 'med',
  2: 'high'
}

export const SaveInterval = [1, 2, 4] as const

export const AdvancedControlKeyList = ['mirror', 'details', 'save_interval'] as const
export type AdvancedControlKeyType = (typeof AdvancedControlKeyList)[number]
export type SaveIntervalType = (typeof SaveInterval)[number]
export type AdvancedControlReq = {
  mirror?: boolean
  details?: DetailsType
  save_interval?: SaveIntervalType
}

export type TrainProjectStartReq = AdvancedControlReq & {
  minutes: number
} & Pick<TrainProject, 'id'>

export type TrainProjectMoreRequest = Omit<TrainProjectStartReq, 'details'>

export const TrainProjectOrderingTypeList = [
  '-modified',
  'modified',
  '-name',
  'name',
  'status',
  '-status',
  '-created',
  'created'
] as const

export type TrainProjectOrderingType = (typeof TrainProjectOrderingTypeList)[number]

export type TrainProjectCreateReq = Pick<TrainProject, 'is_private' | 'name' | 'category'>

export type TrainProjectUpdateReq = Partial<TrainProject> & Pick<TrainProject, 'id'>

export type TrainProjectListReq = {
  scope: Scope
  ordering: TrainProjectOrderingType
  status?: TrainProjectStatus[]
  category?: string[]
  user?: string //TODO adjust based on th ebackend
} & PageParams

export type ProjectSummary = {
  collection_count: number
  project_count: {
    category: string
    total: number
    title?: string
  }[]
  trained_project_count: {
    category: string
    total: number
    title?: string
  }[]
  project_count_total?: number
  trained_project_count_total?: number
}

//Mix Image in  Train project
export type ProjectMix = {
  id: number
  project: number
  created: string
  modified: string
  object_type: ProjectObjectType
  outputs?: UserImage[]
}

export type ProjectAnchors = {
  id: number
  weight: number
}

export type GenerateProjectMixRequest = {
  id: number
  anchors: ProjectAnchors[]
  is_resample: boolean
}

export type GenerateRandomProjectMixRequest = {
  id: number
}

export type ProjectMixCreateRequest = {
  project: number
}
export type ProjectMixRandomListRequest = {
  project: number
}

export type ProjectMixRandomListResponse = {
  results: ImageType[]
}

export type LegacyProject = {
  id: number
  from_set?: number
  to_set?: number
  requester?: string
  hsv?: string
  name?: string
  link?: string
  category?: string
  status?: string
  total_generations?: number
}

export type EngineConfig = {
  id: number //'id'
  name: string // `human readable name. ex: Style Transfer`
  codename: string // 'string, codename used by backend. ex: style_transfer'
  description: string
  target_resolution: string // ex: 256×256'
  in_beta: boolean // if this model only available for special user'
  batch_outputs: number //'int, number of images will generate in each batch. ex: 64'
  batch_time_cost: number //'int, time cost for each batch in minutes. ex: 1'

  unit_batches: number // 'int, minimum batches can be added each time. ex: 10'
  unit_time_cost: number // 'int, time cost for a unit. ex: 10'
  inputs: number //'int, number of input set needed for this model. ex: 1'
  save_interval: number // 'int, iteration for each batch. ex: 500'
  can_inference: boolean // 'boolean, does this model support inference'

  inspiration_capacity_floor: number //'int, minimum number of images inspiration set needed for start training. ex: 30'
  inspiration_capacity_cap: number // 'int, maximum number of images inspiration set accepted for start training. ex: 5000'
  aesthetic_capacity_floor: number //'int, minimum number of images aesthetic set needed for start training. ex: 30'
  aesthetic_capacity_cap: number // 'int, maximum number of images aesthetic set accepted for start training. ex: 5000'

  start_batches_floor: number // 'int, minimum batches needed for first training. ex: 10'
  start_time_cost_floor: number //'int, minimum time needed for first training in minutes. ex: 10'
  start_recommend_batches: number //'int, recommend batches for first training. ex: 50'
  start_recommend_time_cost: number //'int, recommend time for first training in minutes. ex: 50'
  start_batches_cap: number // 'int, maximum batches accepted for first training. ex: 510'
  start_time_cost_cap: number // 'int, maximum time for first training in minutes. ex: 510'

  more_batches_floor: number //'int, minimum batches needed for continue training. ex: 10'
  more_time_cost_floor: number //'int, minimum batches needed for continue training. ex: 10'
  more_batches_cap: number //'int, maximum batches accepted for continue training. ex: 500'
  more_time_cost_cap: number //'int, maximum time for continue training in minutes. ex: 500'

  // Additional Variable from  frontend
  hide?: boolean
  can_mix?: boolean
  can_video?: boolean
} & ProcessUIData

export type EngineConfigResponse = PaginationResponse<EngineConfig>
export type EngineConfigData = { [projectCategory: string]: EngineConfig }

type IncludeImages = { include_images: Array<number> }
type ExcludeImages = { exclude_images: Array<number> }
// If include_images and exclude_images are both present,
// include_images takes priority
export type ModifyImages = Partial<XOR<IncludeImages, ExcludeImages>>

export type ImageSetType = 'inspiration' | 'aesthetic'

export type SelectedCollectionImagesType = {
  selected: SelectedFormat
  direction: SelectDirection
  changed?: boolean
}

export type SelectedCollectionsImagesType = {
  // Combination between project, imageSet type and collection id
  [id: string]: SelectedCollectionImagesType
}
export type Collection = Readonly<
  {
    // Server Data
    id: number
    name: string
    is_private: boolean
    thumbnail: UserImage | null
    created: string
    modified: string
    count: number
    tags: string[]
    category: number
    username: string
    user: Pick<User, 'email' | 'first_name' | 'id' | 'last_name' | 'alias'>
    is_draft: boolean

    // Derived Data
    deleted_at?: string
    imagesData?: PaginationResponse<ImageType> | null
  } & Bookmarkable<'collection'>
>

export const CollectionOrderingTypeList = [
  '-modified',
  'modified',
  '-name',
  'name',
  'last_used',
  '-last_used'
] as const

export type CollectionOrderingType = (typeof CollectionOrderingTypeList)[number]

export type CollectionCreateReq = Pick<Collection, 'is_private' | 'name'>
export type CollectionCopyReq = {
  name: string
  collection: number
}
export type CollectionListReq = {
  scope: Scope
  ordering: CollectionOrderingType
  category?: string
  search?: string
  user?: string //TODO adjust based on backend
} & PageParams

export type CollectionUpdateReq = Partial<Collection> & Pick<Collection, 'id'> & ModifyImages

export type BookmarkType = 'user-image' | 'post' | 'collection' | 'bookmark-create'

export type Bookmark<T extends BookmarkType> = {
  id: number
  user: number
  item: T extends 'user-image'
    ? UserImage
    : T extends 'post'
      ? Post
      : T extends 'collection'
        ? Collection
        : number
  scope: string
  created: string
}

export type BookmarkCreateReq<T extends BookmarkType> = T extends 'user-image'
  ? {
      scope: string
      item: number
    }
  : {
      item: number
    }

export const DEFAULT_BOOKMARK_SCOPE = 'all'
export type UserImageBookmarkListReq = PaginationRequestParams & {
  scope?: string
}
export type UserImageBookmarkListResponse = PaginationResponse<Bookmark<'user-image'>>

//Category for collection
export type Category = {
  id: number
  cover: string
  cover_image: string
  name: string
}
export type Tag = {
  name: string
}
export type TagListReq = {
  ordering: 'name' | '-name'
  search: string
  limit: number
}

export type PageParams = {
  limit?: number
  offset?: number
}

type NextPageParams = {
  next?: string
}

export type PaginationRequestParams = Partial<XOR<PageParams, NextPageParams>>

export type PaginationResponse<T> = Readonly<{
  count: number
  next: string
  previous: string
  results?: Array<T>
}>

export type InputImageSet = {
  id: number
  images: { [collectionId: number]: number[] }
  collections: { [collectionId: number]: Collection }
  collections_order: string[]
  tags: { [collectionId: number]: { removed: boolean } }

  //Derived data
  imageCount?: number
  imagesSet?: { [collectionId: number]: Set<number> }
  imagesData?: { [collectionId: number]: PaginationResponse<ImageType> | null }
  imagesThumbnail?: { [collectionId: number]: ImageType[] | null }
}

export type MoveCollectionReq = {
  sourceId: number
  id: number
  collection: number
}
export type MoveCollectionRes = InputImageSet[]

export type InputImageSetUpdateReq = {
  id: number
  collection: number
  update_existing: boolean //If True, snapshot of collection will refresh from provided Collection. This should be True, if user add an existing collection again.
} & ModifyImages

export type ImageCreateReq = {
  file: File
  collection: number
}

export type ImageListReq = {
  collection: number
  inputs?: number
} & PaginationRequestParams
export type ImageList = ImageType[]

export type SnapshotType = Array<Array<number>>

export type TrainOutputImagesReq = {
  project: number
  batch?: number
  order?: number
  ordering: 'batch' | '-batch' | 'order' | '-order'
} & PaginationRequestParams

export type TrainOutputImagesListReq = {
  project: number
} & PaginationRequestParams

export type TrainOutputImageResponse = PaginationResponse<TrainOutputImage>

export type TrainOutputImage = {
  id: number
  adjustedImage?: UserImage
  image?: UserImage
  order: number
  batch: number
}
export type UpscaleResultReq = {
  id: number
}

/* Block for Inference */
export type InferenceRecordCreateReq = {
  project: number
  resolution?: string
}
export type AddInferenceInputReq = {
  image: File
} & Pick<InferenceRecord, 'id'>

export type InferenceRecord = {
  id: number
  project: number
  user: number
  inputs?: UserImage[]
  outputs?: UserImage[]
  resolution?: string //R256 and R512
  status: 'waiting' | 'started' | 'ready' | 'failed'
  bookmark_scope: string
}
export type InferenceStartReq = Pick<InferenceRecord, 'id'>

/* Block for monetization */

export type Product = {
  id: number
  name: string
  price: number
  type: 'good' | 'service'
  currency: 'usd'
  grade: Equity['type']
  interval: 'hour' | 'yearly' | 'monthly'
  is_active: true
}

export type PaymentItemReq = {
  product: number
  quantity: number
}

export type ProductsResponse = PaginationResponse<Product>

export type PaymentReq = {
  product: number
  quantity: number
  channel: number
  redirect_url?: string
  nonce?: string //required for braintree
  device_data?: string
  save_as_default?: boolean
}

export type Subscription = {
  id: number
  user: User
  status: 'CREATED' | 'ACTIVATED' | 'SUSPENDED' | 'CANCELED' | 'INACTIVE'
  product: number
  next_product: number
  cycle_start: string
  cycle_end: string

  //Derived variable
  next_product_data?: Product
  product_data?: Product
  is_inactive?: boolean
  is_canceled?: boolean
}

export const RUNNING_SUBSCRIPTION_LIST: Subscription['status'][] = [
  'ACTIVATED',
  'SUSPENDED',
  'INACTIVE'
]

export type BraintreePaymentMethod = {
  type: 'creditcard' | 'paypal'
  masked_number?: string
  card_type?: string
}

export type BraintreeCreatePaymentMethodReq = {
  nonce?: string //required for braintree
  device_data?: string
}

export type SubscriptionCreateReq = {
  product: number
}
export type SubscriptionUpdateReq = {
  id: number
  product: number
}

export type Channel = {
  id: number
  name: string
  details: {
    //For Alipay
    callback_url: string
    gateway: string
    pay_url: string

    //for paypal
    client_id: string
  }
  is_active: boolean
}

export type Payment = {
  id: number
  amount: number
  quantity: number
  currency: string
  type: 'charge' | 'subscription'
  product: number
  method: number
  details: {
    redirect_url?: string

    //Alipay
    payment_id?: string
    hosted_url?: string
  }
  status: 'succeed' | 'pending' | 'failed'
}

export type BrainTreeTokenResponse = {
  client_token: string
}

export type PaymentRequest = PageParams

export type CreditChangeLogSource =
  | 'TRAINING'
  | 'PAYMENT'
  | 'PHONE_VALIDATION'
  | 'REFERRAL'
  | 'MANUAL'
  | 'EXPIRED'
  | 'MEMBERSHIP'
  | 'REMOVE_WATERMARK'

export type CreditChangeLog = {
  id: number
  user: number
  amount: number
  payment: number | null // long int, payment linked to this credit'
  created: string
  expire?: string
  source: CreditChangeLogSource
  details: {
    paymentData?: Payment

    //For TRAINING
    project?: number
    payment_id?: number
    projectData?: TrainProject
    name?: string
    batches?: number
    description?: string
    category?: string
    estimate_start_timestamp?: number
    estimate_end_timestamp?: number

    purchase_email?: string

    //For PHONE_VALIDATION
    phone: string
  }
}

export type ListCreditChangeLogResponse = PaginationResponse<CreditChangeLog>

export type CreditOverview = {
  balance: number
  verify_gift: 180
  frozen_balance: number
  can_do_pre_train: boolean
  pre_train_limit: number
  pre_train_usage: number
}

export type CreateDownloadImageReq = {
  image: number
  originalImage?: number
}

export type SketchStyle = {
  id: number
  name: string
  codename: string
  cover: string
  genre: number
}

export type SketchOutputImage = {
  id: number
  style_image: number | null
  image: UserImage

  //Derived state from frontend
  bookmark?: number | null
}

export type SketchProject = {
  id: number
  bookmark_scope: string
  name: string
  style: string
  custom_style: string
  input: UserImage
  input_data: string //base64 encode sketch data
  sketch: UserImage
  sketch_threshold: number
  genre: number
  outputs: SketchOutputImage[]
  modified: string
  created: string
  object_type: ProjectObjectType

  //Data retrieve and generate API
  status?: 'UNKNOWN' | 'PROCESSING' | 'FINISHED'
  generate_num?: number
} & TaskQueue

export type UploadSketchRequest = { id: number; image: File }
export type UpdateThresholdRequest = { id: number; threshold: number }
export type RemoveSketchRequest = { id: number; remove: true }
export const THRESHOLD_LIMIT = {
  MIN: 0,
  MAX: 255
}

export type SketchProjectUpdateReq = Pick<SketchProject, 'id'> &
  Partial<Pick<SketchProject, 'name' | 'input_data'>>
export type SketchProjectCreateReq = Pick<SketchProject, 'name'> & {
  genre?: number
  mix_project?: number
}

export type SketchProjectListReq = PaginationRequestParams
export type SketchProjectListResponse = PaginationResponse<SketchProject>
export type SketchStyleListResponse = PaginationResponse<SketchStyle>

export type SketchOutputListReq = { project: number } & PaginationRequestParams
export type SketchOutputListResponse = PaginationResponse<SketchOutputImage>

export type GenerateSketchProjectReq = {
  id: number
  input: File | Blob | null
  genre: number
  style?: number
  style_image?: number
  custom_style?: File
}

export type SketchStylesListReq = {
  genre: number
} & PaginationRequestParams

export type SketchGenre = {
  id: number
  name: string
  codename: string
  cover: string
  is_active: boolean

  //From Frontend
  desc?: string
  guides?: SketchGuideType[]
  hide?: boolean
} & ProcessUIData
export type GenreListResponse = PaginationResponse<SketchGenre>

export type Question = {
  id: number
  content: string
  created: string
}

export type QuestionCreateReq = {
  content: string
}

/*
 * Code For Social Block
 */
export type SourceResultTypeCollection = Pick<Collection, 'id' | 'name' | 'is_private'> & {
  images: ImageType[]
}

export type ProjectObjectType =
  | 'pretrain_mix_project'
  | 'training_project'
  | 'sketch_project'
  | 'training_mix_project'
  | 'transfer_project'
  | 't2i_project'
  | 't2v_project'
  | 'app_project'
  | 'ST2I_project'
  | ''

export const BOOKMARK_SCOPE_TRAINING = 'training'
export const BOOKMARK_SCOPE_MIX = 'latent'
export const BOOKMARK_SCOPE_SKETCH = 'skt'
export const BOOKMARK_SCOPE_STYLE_TRANSFER = 'st'
export const BOOKMARK_SCOPE_TEXT_TO_IMAGE = 't2i'
export const BOOKMARK_SCOPE_TEXT_TO_VIDEO = 't2v'
export const BOOKMARK_SCOPE_SKETCH_TEXT = 'st2'

export const BOOKMARK_SCOPE_TO_RELATED: { [scope: string]: ProjectObjectType } = {
  [BOOKMARK_SCOPE_MIX]: 'pretrain_mix_project',
  [BOOKMARK_SCOPE_TRAINING]: 'training_project',
  [BOOKMARK_SCOPE_SKETCH]: 'sketch_project',
  [BOOKMARK_SCOPE_STYLE_TRANSFER]: 'transfer_project',
  [BOOKMARK_SCOPE_TEXT_TO_IMAGE]: 't2i_project',
  [BOOKMARK_SCOPE_TEXT_TO_VIDEO]: 't2v_project',
  [BOOKMARK_SCOPE_SKETCH_TEXT]: 'ST2I_project'
}

export type RelatedType = {
  id: number
  is_private?: TrainProject['is_private']
  user?: TrainProject['user']
  category?: TrainProject['category']
  collections?: SourceResultTypeCollection[]
  object_type: ProjectObjectType
  genre: number
}

export type SourceType = 'sketch' | 'project' | ObjectType

export type Post = {
  id: number
  user: Pick<User, 'id' | 'email' | 'first_name' | 'last_name' | 'alias'>
  entity: UserImage
  description: string
  modified: string
  created: string
  bookmark_counts: number
  is_featured: boolean
  clap_counts: number
  is_clapped: boolean
  related: RelatedType
} & Bookmarkable<'post'>

export type PreSignupEmailReq = {
  email: string
}

export type PostCreateReq = {
  description: string
  entity: {
    type: string
    id: number
  }
  related: {
    id: number
    type: ProjectObjectType
  }
}

export type PostUpdateReq = Pick<Post, 'id' | 'is_featured'>

export const PostListOrderingReqList = ['recent', 'trending'] as const
export type PostListOrderingReq = (typeof PostListOrderingReqList)[number]

export const PostScopeList = ['private', 'bookmark', 'public', '', 'all', 'featured'] as const
export type PostScope = (typeof PostScopeList)[number]

export type PostListReq = {
  ordering: PostListOrderingReq
  scope: PostScope
  user?: string //TODO adjust based on backend
} & PageParams

export type PostListResponse = PaginationResponse<Post>

export type SocialImage = Pick<ImageType, 'id' | 'width' | 'height' | 'file'> &
  Partial<Pick<ImageType, 'thumbnail'>>

export type SocialUser = Pick<User, 'id' | 'email' | 'first_name' | 'last_name' | 'alias'> & {
  firebase_uid: string
}

export type ClapReq = {
  post: number
}
export type ClapResponse = {
  status: 0 | -1 | 1
}

export type BannerImage = {
  image: ImageType
  mobile_image: ImageType
  link: string
}

// Comment and thread Block
export type Thread = {
  id: number
  post: number
  user: SocialUser
  body: string
  created: string
  comments: Comment[]

  //Derived Data
  hasMoreComment?: boolean
  isNewData?: boolean
}
export type Comment = {
  id: number
  thread: number // foreign key to Thread
  reply_to: number // foreign key to Comment
  user: SocialUser
  body: string
  created: string

  //derived data
  replyToData: Comment
  isNewData?: boolean
}

export type ThreadCreateReq = {
  post: number
  body: string
}
export type CommentCreateReq = {
  thread: number
  reply_to?: number
  body: string
}

export type ThreadListResponse = PaginationResponse<Thread>
export type CommentListResponse = PaginationResponse<Comment>
export type ThreadListReq = { post: number } & PaginationRequestParams
export type CommentListReq = { thread: number } & PaginationRequestParams

export type GridImage = {
  file: string
  x: number //position of the grid in x axis
  y: number //position of the grid in y axis
  length_x: number //How many grid this image containes in x axis
  length_y: number //How many grid this image containes in y axis
  width: number //Image width (px)
  height: number //Image height (px)
}

//Latent space block

export type SpaceImage = {
  id: number
  file: string
  width: number
  height: number
  total_grid_x: number //total grid in x
  total_grid_y: number //total grid in y
  start_grid_x: number //origin point mapping to total grid
  start_grid_y: number
  level_grid_x: number //number of children in x
  level_grid_y: number
  level: number
  pos_x: number
  pos_y: number
  children: OriginImage[]
  childrenMap?: {
    [naturalKey: string]: SpaceImage
  }
}

export type OriginImage = {
  id: number
  file: string
  height: number
  width: number
  pos_x?: number
  pos_y?: number
  saved_by?: number | null
}

export type UploadErrorType = { image: File; error: string }

export type UploadProgressType = {
  uploading: boolean
  total: number
  success: number
  failed: number
  isFinished?: boolean
  percentage?: number
  processed?: number
  errors: UploadErrorType[]
}
export type CreateUploadImageReq = {
  image: File
  genre: number
}
export type UploadImage = {
  genre: number
  id: number
  image: UserImage
} & TaskQueue<'WAITING' | 'FINISHED' | 'FAILED'>

export type SpaceImageRequest = {
  parent_image_id: number
  pos_x: number
  pos_y: number
}
export type OriginImageRequest = {
  pos_x: number
  pos_y: number
  project?: number
}
export type RandomImageRequest = {
  id: number
  project?: number
}

export type MixImageGenre = {
  id: number
  cover: string
  name: string
  codename: string
  is_active: boolean
  extra_attributes: { [key: string]: { [key: string]: string } }

  //additional frontend data
  desc?: string
  hide?: boolean
} & ProcessUIData

export type MixImageGenreListResponse = PaginationResponse<MixImageGenre>

export type MixImageProject = {
  id: number
  name: string
  genre: number
  sketch?: number
  user: Pick<User, 'id' | 'email' | 'first_name' | 'last_name'>
  outputs?: UserImage[]
  preview?: UserImage
  saved_images: number
  thumbnail: UserImage
  created: string
  modified: string
  bookmark_scope: string
  object_type: ProjectObjectType
} & TaskQueue

export type MixImageUpdateReq = {
  id: number
  name: string
}

export type MixImageListResponse = PaginationResponse<MixImageProject>

export type MixImageCreateReq = {
  name: string
  genre: number
}

export type ControlInfluence = {
  style_weight: number
  content_weight: number
}

export type AnchorType = {
  id: number
  controls: ControlInfluence
  extra_attributes: { [key: string]: number | undefined }

  labels?: { [key: string]: string }
  previewId?: number
}
export type MixImageGenerateReq = {
  id: number
  anchors: AnchorType[]
  is_illustrative?: boolean
  is_resample?: boolean
}

export type MixImageGeneratePreviewReq = {
  id: number
  anchor: AnchorType
}
// MIX VIDEO TYPE
export type Keyframe = {
  id: number
  type: 'random'
}
export type Transition = {
  ease_fn:
    | 'LinearInOut'
    | 'CubicEaseIn'
    | 'CubicEaseInOut'
    | 'CubicEaseOut'
    | 'SineEaseIn'
    | 'SineEaseInOut'
    | 'SineEaseOut'
    | 'ElasticEaseIn'
    | 'ElasticEaseOut'
    | 'ElasticEaseInOut'
    | 'BounceEaseIn'
    | 'BounceEaseOut'
    | 'BounceEaseInOut'

  seconds: number
}

export type UserVideo = {
  id: number
  file: string
  resolution: '1280×720' | '1920×1080' | '2560×1440' | '3840×2160'
  fps: number
  seconds: number
  ext: string
  compression: string
}

export type OutputVideo = {
  video: UserVideo
  created: string
}

export type Clip = {
  id: number
  name: string
  project: {
    type: ClipProjectType
    id: number
  }
  keyframes: UserImage[]
  transitions: Transition[]
  preview?: UserVideo
  outputs?: OutputVideo[]
  created: string
  modified: string

  //Derived data, first video in outputs
  output?: OutputVideo
  canGenerate?: boolean
}

export type UpdateClipReq = {
  id: number
  name?: string
  keyframes?: number[]
  transitions?: Transition[]
}
export type GenerateClipReq = {
  id: number
  resolution: UserVideo['resolution']
  mode: 'stretch' | 'crop' | 'fit'
  smooth?: number
}
export const ClipProjectTypeList = ['latent_genre', 'training_project'] as const
export type ClipProjectType = (typeof ClipProjectTypeList)[number]
export type CreateClipReq = {
  project: { id: number; type: ClipProjectType }
  name: string
}
export type DeleteClipRequest = {
  project: { id: number; type: ClipProjectType }
  clip: number
}
export type ListClipReq = {
  project: number
  type: ClipProjectType
} & PageParams

export const ScaleList = [2, 4, 6, 8, 10, 12] as const
export type ScaleType = (typeof ScaleList)[number]
export type UpscaleImage = {
  id: number
  user: User
  image: UserImage
  output: UserImage // Or number if fetched using list
  paid: boolean
  scale: ScaleType
  method: UpscaleMethod
  controls: {
    texture?: number //0-1, 0.3
    smooth?: number //0-10, 2
    deblocking: boolean
  }
  created: string
  modified: string

  is_user_upload?: boolean //Only available on detail API
}

export const UpscaleOrderingList = ['-created', 'created'] as const

export type UpscaleOrderingType = (typeof TrainProjectOrderingTypeList)[number]

export type ListUpscaleImageReq = PaginationRequestParams & {
  scale?: UpscaleImage['scale']
  method?: UpscaleMethod
  image?: number
  ordering?: UpscaleOrderingType
}

export const UpscaleMethodList = ['bicubic', 'drn', 'waifu2x', 'enh'] as const
export type UpscaleMethod = (typeof UpscaleMethodList)[number]

export type UpscaleImageCreateReq = {
  image_file?: File
  image?: number
  scale: UpscaleImage['scale']
  controls: UpscaleImage['controls']
} & Pick<UpscaleImage, 'method'>

export type AdjustedImageCreateReq = {
  image: number
  controls: AdjustedImage['controls']
}
export type AdjustedImageConnectReq = {
  id: number
  image: number
}

//-- Style Transfer
export type ProArtFilterGenre = {
  id: number
  name: string
  codename: string
  is_active: boolean
} & ProcessUIData

export type ProArtFilterStyle = {
  id: number
  name: string
  codename: string
  cover: string
  genre: number
  group: string
  group_order: number
}

export type ProArtFilterProject = {
  id: number
  name: string
  user: User
  genre: number
  image: UserImage | null
  content: UserImage | null
  modified: string
  created: string
  bookmark_scope: string
  object_type: ProjectObjectType
  style: number | string
  custom_style: UserImage | null
  mask: UserImage | null
  mask_data: string

  //Derived state from frontend
  outputs?: ProArtFilterOutput[]
} & TaskQueue

export type ProArtFilterGenreListResponse = PaginationResponse<ProArtFilterGenre>
export type ProArtFilterStyleListResponse = PaginationResponse<ProArtFilterStyle>
export type ProArtFilterListResponse = PaginationResponse<ProArtFilterProject>

export type ProArtFilterStyleListReq = {
  genre: number
} & PageParams

export type ProArtFilterCreateReq = {
  name: string

  //Derived
  styleId?: number
}

export type ProArtFilterUpdateReq = Pick<ProArtFilterProject, 'id'> &
  Partial<Pick<ProArtFilterProject, 'name' | 'mask_data'>> & { silentUpdate?: boolean }

export type ProArtFilterUploadContentReq = {
  id: number
  content: File
}
export type ProArtFilterOutput = {
  id: number
  image: UserImage

  //Derived state from frontend
  bookmark?: number | null
}

export type ProArtFilterGenerateReq = {
  id: number
  style_weight: number
  content_weight: number
  style?: number
  custom_style?: File | number
  mask?: File | number | null
}
export type ProArtFilterBanner = {
  image: ImageType
  link: string
  description: string
}

export type ProArtFilterOutputListReq = { project: number } & PaginationRequestParams
export type ProArtFilterOutputListResponse = PaginationResponse<ProArtFilterOutput>

// Notification
export type Notification = {
  id: number
  user: number
  title: string
  body: string
  link: string
  read: boolean
  type: 'social' | 'system'
  created: string
  image?: UserImage
}

export type NotificationTemplate = {
  id: number
  codename: string
  type: 'email' | 'in_site'
  group: 'social' | 'system' | 'news' | 'train_project'
  title?: string
  body?: string
  title_template?: string
  body_template?: string
  created: string
  modified?: string

  //Derived variable
  notificationPreference?: NotificationPreference
  displayOption?: boolean
}

export type NotificationPreference = {
  id: number
  user: number
  template: number
  accept: boolean
  created: string
  modified: string
}

export type NotificationPreferenceCreateReq = {
  template: number
  accept: boolean
}
export type NotificationPreferenceUpdateReq = {
  id: number
  accept: boolean
}

export type NotificationUpdateReq = {
  id: number
  read: boolean
}

export type NotificationListReq = { read?: boolean } & PaginationRequestParams

/* Art Mine Model */
export type ArtMineConfig = {
  contract_abi: Record<number, object>
  contract_address: string
  chain_id: number
  audience_id: string

  polygon_chain_id: number
  polygon_contract_address: string
  polygon_contract_abi: Record<number, object>
}

export const CURATION_LIST = ['artist', 'collector'] as const
export type CurationType = (typeof CURATION_LIST)[number]
export const NETWORK_LIST = ['POLYGON', 'ETHEREUM'] as const
export type NetworkType = (typeof NETWORK_LIST)[number]

export type Signature = {
  royalties: string
  expire_at: string
  signature: string
}

export type MineProject = {
  id: number
  network: 'POLYGON' | 'ETHEREUM'
  thumbnail: UserImage
  created: number
  modified: number
  description: string
  mine_limit: number
  mine_count: number
  current_price: string // price for next image to mine
  user_full_name: string
  title: string
  license: string
  email: string
  instagram_url: string
  twitter_url: string
  type: CurationType
  end_price: string
  start_price: string
  is_featured: boolean
  is_published: boolean
  request_transaction: string | null

  //Derived Variable
  isFailed?: boolean
  isProcessing?: boolean
}

export type RoyaltyType = {
  address: string
  initial_sale_fee_in_bp: number
}

export type CreateMineProjectReq = Pick<
  MineProject,
  | 'type'
  | 'title'
  | 'description'
  | 'user_full_name'
  | 'email'
  | 'twitter_url'
  | 'instagram_url'
  | 'network'
  | 'license'
  | 'start_price'
  | 'end_price'
> & {
  thumbnail: number
  images: number[]
  royalties: RoyaltyType[]
}

export const ListMineProjectSortList = [
  'date',
  'trending',
  'top',
  'price',
  '-date',
  '-trending',
  '-top',
  '-price'
] as const

export type ListMineProjectReq = PageParams & {
  sort?: (typeof ListMineProjectSortList)[number]
  user?: string
  type?: 'artist' | 'collector'
  is_featured?: boolean
  is_published?: boolean
}

export type TIProject = {
  id: number
  name: string
  user: User
  thumbnail: UserImage
  image?: UserImage
  input: string
  created: string
  modified: string
  bookmark_scope: string
  object_type: ProjectObjectType
} & TaskQueue

export type TIOutputImage = {
  id: number
  project: number
  image: UserImage
  input: string
  created: string
  seed: boolean

  //Derived state from frontend
  bookmark?: number | null
}

export type TIProjectUpdateReq = {
  id: number
  name: string
}
export type TIProjectCreateReq = Omit<TIProjectUpdateReq, 'id'>
export type GenerateResolution = { width: number; height: number; aspectRatio: [number, number] }
export const REFINEMENT_STEPS = [50, 75, 100] as const
export type TIProjectGenerateReq = {
  id: number
  input?: string
  image?: File | number | null
  anchor?: number
  steps?: (typeof REFINEMENT_STEPS)[number]
} & Partial<GenerateResolution>

export const BASE_GENERATE_SIZE = 512
export const GENERATE_RESOLUTION_PAIR: GenerateResolution[] = [
  { width: BASE_GENERATE_SIZE, height: BASE_GENERATE_SIZE, aspectRatio: [1, 1] },
  { width: 640, height: 448, aspectRatio: [4, 3] },
  { width: 640, height: 320, aspectRatio: [16, 9] },
  { width: 448, height: 640, aspectRatio: [3, 4] },
  { width: 320, height: 640, aspectRatio: [9, 16] }
]

export type TIProjectListReq = PaginationRequestParams

export type TIProjectListResponse = PaginationResponse<TIProject>

export type TIProjectOutputListReq = { project: number } & PaginationRequestParams
export type TIProjectOutputListResponse = PaginationResponse<TIOutputImage>

// TEXT TO VIDEO

export type TVProject = {
  id: number
  name: string
  user: User
  thumbnail: UserImage
  input: string
  created: string
  modified: string
  object_type: ProjectObjectType
} & TaskQueue

export type TVOutputImage = {
  id: number
  project: number
  image: UserImage
  input: string
  created: string
  base_image: UserImage
  //Derived state from frontend
  bookmark?: number | null
}
export type TVProjectUpdateReq = {
  id: number
  name: string
}
export type TVProjectCreateReq = Omit<TVProjectUpdateReq, 'id'>

export type TVProjectGenerateReq = {
  id: number
  input: string
}

export type TVProjectListReq = PaginationRequestParams

export type TVProjectListResponse = PaginationResponse<TVProject>

export type TVProjectOutputListReq = { project: number } & PaginationRequestParams
export type TVProjectOutputListResponse = PaginationResponse<TIOutputImage>

// GENERIC APP PROJECT
export interface GenericApp {
  id: number // int
  owner: User // User
  name: string // string
  description: string // string
  thumbnail: ProcessUIDataItem // UserImage
  cover_image: ProcessUIDataItem // UserImage
  embed_url: string // url used for embedding the app
  enabled: boolean // if False, the app should not appear in frontend
  behind_subscription: boolean // if True, the app should show Upsell banner
}

export interface GenericAppProject {
  id: number // int
  app_name: string
  image?: UserImage
  project_app: number // number
  project_app_data: GenericApp // GenericApp
  user: User // User
  name: string // string
  thumbnail: UserImage // UserImage
  created: string // UTC string
  modified: string // UTC string
  object_type: ProjectObjectType
  bookmark_scope: string
}

export interface GenericAppOutputImage {
  id: number // int
  project: number // GenericAppProject id
  image: UserImage // UserImage
  created: string // UTC string
  bookmark?: number | null
}

export type GenericAppListResponse = PaginationResponse<GenericApp>
export type GenericAppProjectListResponse = PaginationResponse<GenericAppProject>
export type GenericAppProjectCreateReq = {
  project_app: number
  name: string
}

export type GenericAppProjectUpdateReq = {
  id: number
  name: string
}

export type GenericAppOutputImageListResponse = PaginationResponse<GenericAppOutputImage>
export type GenericAppOutputImageListReq = { project: number } & PaginationRequestParams

// SKETCH TEXT TO IMAGE
export interface SketchTextOutputImage {
  id: number
  image: UserImage
  seed: bigint
  prompt: string
  prompt_negative: string

  //Derived state from frontend
  bookmark?: number | null
}
export interface SketchTextInputImage {
  id: number
  width: number
  height: number
  file: string // Ensure this is a valid URL in your code
}

export interface SketchTextProcess {
  id: number
  name: string
  codename: string
  order: number
  description: string
}

export interface SketchTextModel {
  id: number
  name: string
  codename: string
  description: string
  cover_image: UserImage
}

export interface SketchTextStyles {
  id: number
  codename: string
}

export interface SketchTextGenre {
  id: number
  name: string
  codename: string
  cover: string // Ensure this is a valid URL in your code
  generate_api_url?: string
  is_active: boolean
}

export interface SketchTextProject extends TaskQueue {
  id: number
  name: string
  genre: number // this is the ID of a Genre
  input_img: UserImage // this is an InputImage object, the original image
  input_data: string
  sketch: UserImage
  prompt: string
  prompt_negative: string
  sketch_threshold_low: number // this is a float, range 0-1
  sketch_threshold_high: number // this is a float, range 0-1
  created: string
  modified: string
  thumbnail: UserImage
  object_type: ProjectObjectType
  prompt_strength: number | null
  control_strength: number | null
  bookmark_scope: string
}

export interface SketchTextListOutputReq {
  project: number // ID of a Project, current user must be owner. Required.
  limit?: number // Optional. Default is 20.
  offset?: number // Optional. Default is 0.
}

export interface SketchTextCreateReq {
  name: string // Required. Max length is 127.
  genre: number // ID of an active Genre. Required.
}

export type SketchTextUpdateReq = Pick<SketchTextProject, 'id'> &
  Partial<Pick<SketchTextProject, 'name' | 'input_data'>>

export type SketchTextUploadReq = { id: number; image: File }
export type SketchTextUpdateThresholdReq = {
  id: number
  sketch_threshold_low: number // Range is 0-1
  sketch_threshold_high: number // Range is 0-1
}
export type SketchTextRemoveSketchReq = { id: number; remove: true }

export type SketchTextOutputListReq = {
  project: number
} & PaginationRequestParams

export const NUMBER_OF_SAMPLES = 4
export const SAMPLES_ARRAY: number[] = Array.from(Array(NUMBER_OF_SAMPLES).keys()).map(
  value => value + 1
)

export type SketchTextGenerateReq = {
  image?: File // filled with sketch //
  prompt?: string | null // Optional
  prompt_negative?: string | null
  seed: -1 | number
  process?: string //these are the same as the codename from the process api
  model?: string //model codename from the model selection api
  mask?: File

  samples?: number //number of output images, currently maximum is 4
  enhance_prompt?: string
  prompt_strength: number
  control_strength: number

  id: number
  generate_api_url?: string
} & Partial<Pick<GenerateResolution, 'height' | 'width'>>

import { SaveIntervalType } from 'models/ApiModels'

export type AddCreditSource = 'train' | 'download' | 'profile' | 'upsell'

export const INTERVAL_DELAY = 1000
export const TRY_MAX_COUNT = 20

export const SAVE_INTERVAL_MULTIPLIER_MAP: { [key in SaveIntervalType]: number } = {
  1: 1,
  2: 2,
  4: 4
}
export const DEFAULT_SAVE_INTERVAL = 1
export const CREDIT_TO_QUANTITY_RATIO = 20
